import { FC, ReactNode } from "react";
import { Select } from "antd";
import { observer } from "mobx-react";

import { ForecastMethod } from "features/techForecast/models/well/methods";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { ValidationInput } from "../legacyInputs/validationInput";
import { ValidationInputPercent } from "../legacyInputs/validationInputPercent";
import { Param } from "../param";

const tr = (method: ForecastMethod, param: "a" | "k") => {
  if (param === "k") {
    return param;
  }
  const TR: Record<string, string> = {
    geometric_progression: "падения",
  };
  return TR[method] ?? "a";
};

const DropCurveCoefficientInput: FC<{ param: "a" | "k"; mode: "oil" | "liquid" | "waterCut" }> = observer(({ param, mode }) => {
  const model = useTechForecastModel();
  const settings = model.currentForecast.settings[mode];
  const isPercent = settings.method === "geometric_progression";
  const Input = isPercent ? ValidationInputPercent : ValidationInput;
  const paramTitle: ReactNode = (
    <>
      <span>Коэффициент {tr(settings.method, param)}</span>
      {isPercent && (
        <>
          ,
          <Select
            value={settings.percentType}
            onChange={(value: "month" | "year") => {
              settings.setPercentType(value);
            }}
            options={[{ value: "month", label: "%/мес" }, ...(mode !== "waterCut" ? [{ value: "year", label: "%/год" }] : [])]}
            size={"small"}
          />
        </>
      )}
    </>
  );

  return (
    <Param title={paramTitle}>
      <Input
        key={`${model.currentKey}_${mode}_${settings.method}`}
        value={settings[param]}
        onSave={settings[`${param}Holder`]}
        min={-Infinity}
        max={Infinity}
      />
    </Param>
  );
});

export { DropCurveCoefficientInput };
