import { FC } from "react";
import { Button, Divider, Input } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { observer } from "mobx-react";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Icon } from "elements/icon/icon";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { MonthPicker } from "elements/inputs/monthPicker";
import { NodeType, useInfrastructureMapContext } from "features/infrastructure/infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { findMatch } from "features/infrastructure/utils";
import { Nodes } from "models/project/fact/infrastructure/nodes";
import { Mine, ResPipeCost } from "services/back/infrastructure/calculate";
import { StationType } from "services/back/infrastructure/catalog";
import { Station } from "services/back/infrastructure/types";

import { ReactComponent as DrainIcon } from "../../../icons/drain.svg";
import { ReactComponent as NodeIcon } from "../../../icons/node.svg";
import { ReactComponent as PumpingIcon } from "../../../icons/pumping.svg";
import { ReactComponent as SourceIcon } from "../../../icons/source.svg";
import { CoordParams } from "../../ui/coordParams";
import { FieldParams } from "../../ui/fieldParams";
import { ParamsTabs } from "../../ui/paramsTabs/paramsTabs";

import { TechnologyParams } from "./technologyParams/technologyParams";
import { ReactComponent as SelectNodeIcon } from "./selectNode.svg";

import cn from "./selectedItem.module.less";

const findItemInCatalog = (selectedItem: NodeType, stations: StationType[]) => {
  const tmp = Nodes.getStationAttributes(selectedItem);
  return findMatch<StationType>(tmp, stations)?.title || "Нет соответствующей станции";
};

const getCatalogTitle = (selectedItem: NodeType, stations: StationType[]): string =>
  ({
    mine: "Куст",
    drain: "Пункт сбора",
    source: "Источник",
    node: "Узел",
    pumping: findItemInCatalog(selectedItem, stations),
  }[selectedItem.type] || "Узел");

const getEconomicParams = (data: ResPipeCost<Mine | Station>["res"], uuid: string) => {
  const node = (data ?? []).find((el) => el.nodeUuid === uuid);
  return { construction: node?.constrNominal, reconstruction: node?.reconstrNominal };
};

const icons = {
  oilPipe: <SelectNodeIcon />,
  waterPipe: <SelectNodeIcon />,
  source: <SourceIcon />,
  pumping: <PumpingIcon />,
  drain: <DrainIcon />,
  node: <NodeIcon />,
} as const;

type DrainSourceParamsProps = {
  update: (item: Partial<NodeType>) => void;
  save: (pressure: number) => void;
  range: [number, number];
  selectItem: NodeType;
};

const DrainSourceParams: FC<DrainSourceParamsProps> = ({ update, save, range, selectItem }) => {
  const { startedAt, pressure, altitude, x, y } = selectItem;

  return (
    <div className={cn.tehnologyWrapper}>
      <FieldParams title="Дата ввода" className={cn.fieldItem}>
        <MonthPicker
          className={cn.date}
          placeholder="Выбрать..."
          locale={locale}
          value={startedAt}
          onChange={(date) => update({ startedAt: date })}
          start={dayjs(range[0].toString())}
          end={dayjs(range[1].toString())}
        />
      </FieldParams>
      <FieldParams title={selectItem.type === "drain" ? "Давление приема, МПа" : "Давление нагнетания, МПа"} className={cn.fieldItem}>
        <InputNumber bordered placeholder="50 МПа..." value={pressure} onBlur={({ target }) => save(Number(target.value))} />
      </FieldParams>
      <FieldParams title="Альтитуда" className={cn.fieldItem}>
        <InputNumber bordered placeholder="266..." value={altitude} onUpdate={(altitude) => update({ altitude: altitude ?? undefined })} />
      </FieldParams>
      <Divider className={cn.divider} />
      <CoordParams className={cn.coordParams} coordinates={{ x, y }} />
    </div>
  );
};

const OtherParams: FC<{ selectedItem: NodeType }> = observer(({ selectedItem }) => {
  const { range, nodes, calculateStore, saveDrainSources } = useInfrastructure();

  const minesEconomicParams = getEconomicParams(calculateStore.economicData?.mines!, selectedItem?.uuid!);
  const stationsEconomicParams = getEconomicParams(calculateStore.economicData?.stations!, selectedItem?.uuid!);
  const economicParams = selectedItem.type === "mine" ? minesEconomicParams : stationsEconomicParams;

  if (selectedItem.type === "node") {
    return (
      <div className={cn.nodeParamsWrap}>
        <CoordParams coordinates={{ x: selectedItem.x, y: selectedItem.y }} />
      </div>
    );
  }
  if (selectedItem.type === "drain" || selectedItem.type === "source") {
    return (
      <DrainSourceParams
        range={[range.from, range.to]}
        selectItem={selectedItem}
        update={(item) => nodes.update({ uuid: selectedItem.uuid, ...item })}
        save={(pressure) => saveDrainSources({ uuid: selectedItem.uuid, pressure })}
      />
    );
  }

  if (selectedItem.type === "mine" || selectedItem.type === "pumping") {
    return (
      <ParamsTabs className={cn.nodeTabs} economicParams={economicParams}>
        <TechnologyParams selectedItem={selectedItem} />
      </ParamsTabs>
    );
  }
  return <></>;
});

type Props = {
  selectedItem: NodeType | null;
  goCatalog: () => void;
};

const SelectedItem: FC<Props> = observer(({ selectedItem, goCatalog }) => {
  const { updateNode, removeNodes } = useInfrastructureMapContext();
  const { catalog, drainSourceBoundaryConditions } = useInfrastructure();

  const drainSourcePressure = drainSourceBoundaryConditions.filter((el) => el.nodeUuid === selectedItem?.uuid).at(-1)?.boundaryCondition?.pressure;
  const pressure = (selectedItem?.type === "drain" || selectedItem?.type === "source" ? drainSourcePressure : selectedItem?.pressure) ?? 0;

  if (!selectedItem) {
    return <></>;
  }

  return (
    <>
      <div className={cn.field}>
        {selectedItem.type === "mine" ? (
          <div>{selectedItem.title}</div>
        ) : (
          <>
            <Input value={selectedItem.title} onChange={({ target }) => updateNode(selectedItem.uuid, { title: target.value })} />
            <DeleteButton title={`Вы точно хотите удалить ${selectedItem.title}`} onClick={() => removeNodes([selectedItem.uuid])}>
              <Button danger>Удалить</Button>
            </DeleteButton>
          </>
        )}
      </div>
      {selectedItem.type !== "mine" && (
        <div className={cn.catalogBtnWrapper}>
          <div className={cn.selectedName}>
            <Icon width="16px" height="16px" viewBox="0 0 16 16" content={icons[selectedItem.type]} />
            {getCatalogTitle(selectedItem, catalog.stations?.map((el) => el)!)}
          </div>
          <Button onClick={goCatalog}>В каталог</Button>
        </div>
      )}
      <OtherParams selectedItem={{ ...selectedItem, pressure }} />
    </>
  );
});

export { SelectedItem };
