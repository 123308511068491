import { FC, useMemo } from "react";
import { observer } from "mobx-react";

import { Loader } from "elements/loader";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { TreeRoot } from "models/tree/tree";

import { Chart } from "./chart";
import { SummaryModel } from "./summaryModel";
import { Table } from "./table";

const View: FC<{ tree: TreeRoot<Well>; className?: string }> = observer(({ tree, className }) => {
  const forecast = useForecast()!;

  const summaryModel = useMemo(() => new SummaryModel(tree, forecast), [forecast, tree]);

  return (
    <div className={className}>
      {summaryModel.data?.length ? (
        <>
          <Chart summaryModel={summaryModel} />
          <Table summaryModel={summaryModel} />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
});

export { View };
