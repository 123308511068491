import { FC, useMemo } from "react";
import { ChildrenStoreArray, TableNode, Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";

import { ChannelInfo } from "elements/charts/channelsManager";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { zip } from "utils/itertools";

import { YearDatum } from "./constants";
import { SummaryModel } from "./summaryModel";

import cn from "./summary.module.less";

type DRow = {
  title: string;
  measure: string | null;
  [year: number]: number;
};

class ParamsStore extends TableNode<DRow, ParamRow> {
  constructor(public summaryMode: SummaryModel) {
    super(null);
    this.childrenStore = new ChildrenStoreArray(
      this,
      summaryMode.activeChannels.map((p) => new ParamRow(this, p))
    );
  }
}

class ParamRow extends TableNode<DRow> {
  asDRow = (): DRow => ({
    title: this.param[1].title,
    measure: this.param[1].axis,
    ...Object.fromEntries(
      zip(
        (this.parent.summaryMode.years ?? []).map((y) => y.year()),
        (this.parent.summaryMode.data ?? []).map((v) => v[this.param[0] as keyof YearDatum])
      )
    ),
  });

  constructor(private parent: ParamsStore, private param: [string, ChannelInfo]) {
    super(parent);
  }
}

type TableProps = {
  summaryModel: SummaryModel;
};

const Table: FC<TableProps> = observer(({ summaryModel }) => {
  const store = new ParamsStore(summaryModel);
  const columns: Column[] = useMemo(
    () => [
      {
        dataKey: "title",
        title: "Параметр",
        type: "string",
        width: 300,
        isSticky: true,
        hideFilter: true,
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        type: "string",
        width: 100,
        isSticky: true,
        hideFilter: true,
      },
      ...(summaryModel.years.map(
        (y): Column => ({
          dataKey: `${y.year()}`,
          title: y.year(),
          type: "number",
          width: 100,
          hideFilter: true,
        })
      ) ?? []),
    ],
    [summaryModel.years]
  );

  return (
    <SimpleTableContext
      exportFileName="Сводка по прогнозу технологических показателей"
      columns={columns}
      data={store}
      hideExpandColumn
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#e5e7eb",
      }}
    >
      <div className={cn.table}>
        <Widget headerClassName={cn.tableHeader} />
      </div>
    </SimpleTableContext>
  );
});

export { Table };
