import { FC } from "react";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react-lite";

import { Icon } from "elements/icon/icon";
import { PipeType } from "features/infrastructure/infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";
import { calculateDistance } from "features/infrastructure/infrastructureMap/utils";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";

import { ReactComponent as ResetIcon } from "./reset.svg";

import cn from "./selectedItem.module.less";

const convertToKm = (length?: number | null) => {
  if (!length) return null;
  return Number((length / 1000).toFixed(2));
};

type OisPipeProps = { pipe: PipeType };

const OisTooltip: FC<OisPipeProps> = observer(({ pipe }) => {
  const { nodes, pipes } = useInfrastructure();
  const calculateLength = (pipe: PipeType) => {
    const length = Number(
      calculateDistance({
        from: nodes.at(pipe.from)!,
        to: nodes.at(pipe.to)!,
        unit: "km",
      }).toFixed(2)
    );
    pipes.update({ uuid: pipe.uuid, length });
  };

  if (!pipe.length) {
    return (
      <div className={cn.pipelengthResetBtnTooltip}>
        {pipe.oisLength && (
          <Button onClick={() => pipes.update({ uuid: pipe.uuid, length: convertToKm(pipe.oisLength) })}>Взять длину из OIS PIPE</Button>
        )}
        <Button onClick={() => calculateLength(pipe)}>Вычислить длину по Евклиду</Button>
      </div>
    );
  }

  return <Button onClick={() => pipes.update({ uuid: pipe.uuid, length: convertToKm(pipe.oisLength) })}>Вернуться к длине из OIS PIPE</Button>;
});

type Props = {
  selectedItem: PipeType;
};

const PipeLengthResetButton: FC<Props> = ({ selectedItem }) => {
  const { length, oisLength } = selectedItem;

  if (oisLength && length && convertToKm(oisLength) === length) return <></>;

  if (length && !oisLength) return <></>;

  return (
    <Tooltip trigger="click" overlayInnerStyle={{ background: "white" }} title={<OisTooltip pipe={selectedItem} />}>
      <Button icon={<Icon width="16" height="16" viewBox="0 0 16 16" content={<ResetIcon />} />} />
    </Tooltip>
  );
};

export { PipeLengthResetButton };
