import { FC, ReactNode } from "react";
import { Tooltip, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Icon } from "elements/icon/icon";

import { ReactComponent as FlagIcon } from "../icons/flag.svg";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { ForecastStatus } from "../models/well/wellTechForecast";
import { useTechForecastModel } from "../useTechForecastModel";

import { WellTooltip } from "./wellTooltip";

import cn from "./wellItem.module.less";

type WellItemProps = {
  index: number;
  groupIndex: number;
};

const ICONS_MAP: Record<ForecastStatus, { tooltip: string; icon: string | ReactNode }> = {
  // ошибок не обнаружено но и настройки никто не просматривал, расчет никто не запускал
  changed: {
    tooltip: "Расчет не выполнялся",
    icon: "⚙️",
  },
  // расчет проводился ранее, в текущей сесси никто не менял настройки
  notChanged: {
    tooltip: "Расчет выполнялся ранее",
    icon: "📝",
  },
  // дефолтных, сохранённых и настроек, применённых через "применить на группу" хватило чтобы провести расчет но его не просматривали, дискетка будет работать так, что расчет сразу улетит на сервер
  notViewed: {
    tooltip: "Расчет выполнен, но не просмотрен",
    icon: "🔎",
  },
  // часть настроек не заполнена, расчет не проведён
  wrong: {
    tooltip: "Расчет не проведен, параметры не заполнены",
    icon: "⚠️",
  },
  // при выполнении прогноза произошла ошибка солвера (могут быть как ошибки реализации так и несовместности настроек, не идентифицированные на фронте)
  failed: {
    tooltip: "Ошибка при выполнении расчета",
    icon: "📛",
  },
  // настройки расчета сохранены, прогноз построен, просмотрен и сохранён
  ok: {
    tooltip: "Расчет выполнен и сохранён",
    icon: "✅",
  },
  // идёт расчет
  loading: {
    tooltip: "Расчет выполняется",
    icon: "⏳",
  },
  // расчет проведён но не физичен
  warn: {
    tooltip: "Результат не отвечает критериям физичности",
    icon: "⚠️",
  },
};

const TOOLTIP_OVERLAY_STYLE = { maxWidth: "350px" };

const WellItem: FC<WellItemProps> = observer(({ index: collapsedIndex, groupIndex: rawGroupIndex }) => {
  const model = useTechForecastModel();
  const groupIndex = model.wells.groupAt(rawGroupIndex);
  const index = model.recoverIndexToUnCollapsed(collapsedIndex, groupIndex);
  const event = model.wells.atEvent(index, groupIndex);
  if (event === undefined) {
    /* virtuoso передаётся новый размер групп при колапсе но он заказывает
    рендер элементов, которые уже вышли из области видимости, тем не менее
    не отображая их. Это обработчик такого случая*/
    return <div style={{ height: 1, width: 1 }} />;
  }
  const { status, currentResult } = model.forecast(event);

  const statusTooltip = (() => {
    if (status === "warn") {
      const FLAG_PARAMS = {
        isCorrectOilRateT: "дебита нефти",
        isCorrectLiquidRateM3: "дебита жидкости",
        isCorrectWaterCutVol: "обводненности",
      } as const;
      const p = currentResult?.data?.forecastProduction;
      if (p === undefined) {
        return ICONS_MAP[status].tooltip;
      }
      const invalidParams: string[] = (Object.keys(FLAG_PARAMS) as Array<keyof typeof FLAG_PARAMS>).filter((k) => !p[k]).map((k) => FLAG_PARAMS[k]);
      return `При составлении прогноза получены не физичные значения ${invalidParams.join(", ")}`;
    }
    return ICONS_MAP[status].tooltip;
  })();
  return (
    <div className={classNames(cn.item, model.currentSelection === event && cn.selected)} onClick={model.selectedHolder(event)}>
      <Icon content={<FlagIcon />} viewBox="0 0 12 14" className={cn["flag-icon"]} />
      <Typography.Text className={cn.label}>
        {event.well.title}/{[event.intervention?.typeName, event.well.type].filter((v) => v).join(" ")}
        {event.stratumId !== null && groupIndex === 0 && `(${event.stratum?.title})`}
      </Typography.Text>
      <Tooltip title={statusTooltip}>{ICONS_MAP[status].icon}</Tooltip>
      <Tooltip overlayStyle={TOOLTIP_OVERLAY_STYLE} color="#FFF" title={<WellTooltip well={event.well} />}>
        <div>
          <Icon content={<InfoIcon />} viewBox="0 0 16 16" className={cn["info-icon"]} />
        </div>
      </Tooltip>
      <DeleteButton className={cn.remove} onClick={model.removeHolder(index)} />
    </div>
  );
});

export { WellItem };
