import { useRef } from "react";
import { Widget } from "@okopok/components/Table";
import { Button, Card, Tooltip } from "antd";
import { observer } from "mobx-react-lite";

import { Icon } from "elements/icon/icon";
import { Loader } from "elements/loader";
import { DelayedShow } from "elements/modal/delayShow";
import { ModelContentComponentType, useModal } from "elements/modal/modal";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";

import { NodeType } from "../infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";
import { useInfrastructure } from "../useInfrastructure";

import { Mines } from "./mines";
import { ReactComponent as WarningIcon } from "./warningIcon.svg";

import cn from "./importMines.module.less";

type ModalType = { mines: Mines | null };

const columns: Column[] = [
  {
    title: "Кустовая площадка",
    dataKey: "title",
    type: "string",
  },
  {
    title: "Координата X, м",
    dataKey: "x",
    type: "number",
    editable: true,
  },
  {
    title: "Координата Y, м",
    dataKey: "y",
    type: "number",
    editable: true,
  },
];

const ImportModalContent: ModelContentComponentType<NodeType[], ModalType> = observer((props) => {
  const { dataRef: ref, reject, setOnAccept, accept } = props;

  const store = ref.current.mines ?? undefined;

  const onFinish = async () => {
    return {
      result: true,
      data: store?.unsavedMines,
    };
  };

  setOnAccept(onFinish);

  if (store?.childrenStore?.size === 0) {
    return <Loader />;
  }

  return (
    <>
      <SimpleTableContext hideExpandColumn exportFileName="" data={store} columns={columns} selectable>
        <div style={{ height: "100%" }}>
          <DelayedShow delay={200}>
            <Widget className={cn.widget} />
          </DelayedShow>
        </div>
      </SimpleTableContext>
      <div className={cn.footer}>
        {!store?.isComplete && (
          <Card className={cn.warningBlock}>
            <Icon content={<WarningIcon />} width="16px" height="16px" viewBox="0 0 16 16" />
            Задайте координаты выбранных кустов для их импорта
          </Card>
        )}
        <div className={cn.buttonsWrapper}>
          <Button onClick={reject}>Отмена</Button>
          <Tooltip title={store?.selectedMinesLength === 0 ? "Выберите кусты" : undefined}>
            <Button onClick={accept} disabled={!store?.isComplete || store?.selectedMinesLength === 0} type="primary">
              Импортировать
            </Button>
          </Tooltip>
        </div>
      </div>
    </>
  );
});

const useImportMinesModal = () => {
  const showModal = useModal<NodeType[], ModalType>();

  const { nodes } = useInfrastructure();

  const val: { current: ModalType } = useRef({ mines: null });

  return async (store: Mines) => {
    val.current.mines = store;
    return showModal(ImportModalContent, val, "Импорт кустов", null, null, "700px", null)
      .then((data) => data.map((el) => nodes.pushNode(el)))
      .catch(() => null);
  };
};

export { useImportMinesModal };
