import { useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import classNames from "classnames";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

import { HTRTypesModel } from "./tableModel";

import cn from "./htrTypes.module.less";

const HTRTypes = observer(() => {
  const fact = useFact()!;
  const forecast = useForecast() ?? null;

  const store = useMemo(() => new HTRTypesModel(fact, forecast), [fact, forecast]);
  const columns = useMemo(
    (): Column[] => [
      {
        dataKey: "year",
        title: "Год",
        type: "date",
        renderFormat: "year",
        width: 100,
        isSticky: true,
        hideFilter: true,
      },
      ...[...(fact.producingObjects.values ?? [])].map(
        (po): Column => ({
          dataKey: `${po.id}`,
          key: `${po.id}`,
          title: po.title,
          type: "select",
          options: global.htrTypes.selector,
          width: { min: 300, max: 600, competitiveness: 1 },
          hideFilter: true,
          editable: true,
        })
      ),
    ],
    [fact]
  );

  return (
    <SimpleTableContext
      exportFileName="Параметры объектов разработки. Категории ТРИЗ"
      columns={columns}
      data={store}
      showIndexColumn={false}
      hideExpandColumn
      tableSettingsId="producing_objects_params_htr_types"
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: classNames({
            [cn.summaryRow]: indexPath[0] + 1 === 0,
            [cn.tableRowPlain]: expand === undefined,
            [cn.tableRowPrimary]: expand !== undefined && indexPath.length === 1,
            [cn.tableRowSecondary]: expand !== undefined && indexPath.length > 1,
          }),
        }),
      }}
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
    >
      <PageFrameTitlePortal model={store} onSave={store.submit} permissionSection="tech" />
      <Widget headerClassName={cn.tableHeader} />
    </SimpleTableContext>
  );
});

export { HTRTypes };
