import { makeAutoObservable } from "mobx";

import { Statuses, StatusSections } from "features/scenariosList/statusCard";
import { global } from "models/global";
import { getScenarioStatus, setScenarioStatus } from "services/back/scenario";

import { Forecast } from "./forecast";

class ScenarioStatus {
  status: Statuses | null = null;
  userId?: number;
  loadingState = true;

  constructor(private forecast: Forecast, private projectId: number, private section?: StatusSections) {
    this.init();
    makeAutoObservable(this);
  }

  setStatus(status: Statuses | null) {
    this.status = status ?? "inProgress";
  }

  setUserId(id: number | undefined) {
    this.userId = id;
  }

  updateStatus = async (status: Statuses) => {
    this.loadingState = true;
    const userId = global.user?.id!;
    const newStatus = await setScenarioStatus(this.projectId, this.forecast.id, status, userId, this.section);
    this.setStatus(newStatus?.status ?? null);
    this.setUserId(newStatus?.userId);
    this.loadingState = false;
  };

  init() {
    getScenarioStatus(this.projectId, this.forecast.id, this.section).then((res) => {
      this.setStatus(res?.status ?? null);
      this.setUserId(res?.userId);
      this.loadingState = false;
    });
  }

  get isLoading(): boolean {
    return !this.status && this.loadingState;
  }
}

export { ScenarioStatus };
