import { type FC, useEffect, useMemo, useState } from "react";
import { Table } from "@okopok/components/Table/Table";
import { Input, Typography } from "antd";
import { observer } from "mobx-react";
import { useParamsModel } from "routing/outlines/params/paramsPage";

import { Format } from "elements/format/format";
import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Column } from "features/tableDebug/simpleTable";
import { SimpleTableContext } from "features/tableDebug/simpleTable";
import { split } from "features/useEditRow/useEditRow";
import { ParamsRow } from "features/useMetrics/paramsRow";

import { OptionedScalar } from "./optionedScalar";
import { Scalar } from "./scalar";
import { GenericTableStore } from "./tableModel";

import cn from "./genericPage.module.less";

const InputMulti = ({ value, row, columnKey }: any) => {
  const [val, setVal] = useState("");
  useEffect(() => {
    const parsed = parseFloat(val);
    if (parsed !== value) {
      setVal(`${value ?? ""}`);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
  if (row.update === undefined) {
    return <Format>{null}</Format>;
  }
  const onChange = (e: any) => {
    let inputValue = e.target.value as string;
    if (inputValue.endsWith(",")) {
      inputValue = inputValue.slice(0, -1);
    }
    const vals = split(inputValue)
      .map((v) => parseFloat(v))
      .map((v) => (isFinite(v) ? v : null));
    if (vals.length > 1) {
      row.update?.(parseInt(columnKey), vals);
    } else {
      setVal(inputValue);
      if (inputValue === "" && value === null) {
        return;
      }
      const parsed = parseFloat(inputValue);
      if (parsed !== value) {
        row.update?.(parseInt(columnKey), [isFinite(parsed) ? parsed : null]);
      }
    }
  };
  return <Input variant="borderless" value={val} onChange={onChange} />;
};

const inputMultiRender = (columnKey: string) => (value: any, row: any) => {
  return <InputMulti value={value} row={row} columnKey={columnKey} />;
};

const GenericPage: FC<{}> = observer(() => {
  const model = useParamsModel();
  const ScalarInput = model.optionedScalars === null ? Scalar : OptionedScalar;
  const modelTable = model?.table;

  const getTotalRows = (items: ParamsRow[]) => {
    return items.reduce((count: number, item: ParamsRow) => {
      let total = 1;
      if (item.children && item.children.length > 0) {
        total += getTotalRows(item.children);
      }

      return count + total;
    }, 0);
  };
  const lineCount = modelTable?.tableItems ? getTotalRows(modelTable?.tableItems) : 0;

  const columns = useMemo<Column[]>(
    () =>
      modelTable
        ? [
            {
              title: "Параметр",
              dataKey: "param",
              type: "string",
              width: 350,
              showEditRowBtn: true,
              isSticky: true,
            },
            {
              title: "Единица измерения",
              dataKey: "measure",
              type: "string",
              width: 100,
              isSticky: true,
            },
            ...[...modelTable.years].map(
              (year): Column => ({
                title: `${year}`,
                dataKey: `${year}`,
                key: `${year}`,
                type: "number",
                width: 140,
                editable: true,
              })
            ),
          ]
        : [],
    [modelTable]
  );

  const customRenders = useMemo(
    () => Object.fromEntries([...(modelTable?.years ?? [])].map((year) => [year, inputMultiRender(`${year}`)])),
    [modelTable]
  );

  const store = useMemo(() => (modelTable && new GenericTableStore(modelTable, modelTable.years, modelTable.tableItems)) ?? undefined, [modelTable]);

  if (model.table === null && model.scalar === null && model.optionedScalars === null) {
    return <FullScreenEmpty>В разработке</FullScreenEmpty>;
  }

  if (model.isOnlyTable) {
    return (
      <SimpleTableContext
        exportFileName={modelTable?.title || ""}
        columns={columns}
        data={store}
        customRenders={customRenders}
        tableOptions={{
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        }}
      >
        <div style={{ height: "100%", marginTop: "8px" }}>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </div>
      </SimpleTableContext>
    );
  }

  return (
    <SimpleTableContext
      exportFileName=""
      columns={columns}
      data={store}
      customRenders={customRenders}
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      }}
    >
      <div className={cn.generic}>
        <ScalarInput />
        {model.isHaveTable && (
          <div className={cn.tableWrap}>
            <Typography.Title level={2}>Параметры по годам</Typography.Title>
            <div className={cn.simpleTable} style={{ "--line-count": `${lineCount}` } as React.CSSProperties}>
              <Table headerClassName={cn.tableHeader} className={cn.table} />
            </div>
          </div>
        )}
      </div>
    </SimpleTableContext>
  );
});

export { GenericPage };
