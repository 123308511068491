import { PRODUCTION_FIELDS } from "services/back/production";

import type { AggregateFunction } from "./aggregate";
import type { ProductionDatum as Datum } from "./production";

const defaultDatum = (): Datum => ({
  prod_days: null,
  inj_days: null,
  oil_prod: null,
  oil_rate: null,
  liquid_prod: null,
  liquid_prod_t: null,
  apg_prod: null,
  water_prod: null,
  water_inj: null,
});

const sumUp: AggregateFunction<Datum, Datum> = (datums) => {
  if (datums.length === 1) {
    return datums[0];
  }
  const result = defaultDatum();
  for (const datum of datums) {
    for (const field of PRODUCTION_FIELDS) {
      const a = result[field];
      const b = datum[field];
      result[field] = (a || b) && (a ?? 0) + (b ?? 0); // null if both null, sum otherwise (null = 0)
    }
  }
  return result;
};

const sumUpMaxDays: AggregateFunction<Datum, Datum> = (datums) => {
  if (datums.length === 1) {
    return datums[0];
  }
  const result = defaultDatum();
  for (const datum of datums) {
    for (const field of PRODUCTION_FIELDS) {
      const a = result[field];
      const b = datum[field];
      if (field === "prod_days" || field === "inj_days") {
        result[field] = (a || b) && Math.max(a ?? 0, b ?? 0);
      } else {
        result[field] = (a || b) && (a ?? 0) + (b ?? 0); // null if both null, sum otherwise (null = 0)
      }
    }
  }
  return result;
};

export { sumUp, sumUpMaxDays };
