import { FC } from "react";
import { Button, Tooltip } from "antd";

import { useMines } from "./mines";
import { useImportMinesModal } from "./useImportMinesModal";

import cn from "./importMines.module.less";

const ImportMines: FC = () => {
  const importMinesModal = useImportMinesModal();

  const mines = useMines();

  if (mines.childrenStore?.length === 0) {
    return <></>;
  }

  return (
    <Tooltip title="Есть кусты не отображенные в инфраструктуре">
      <Button onClick={() => importMinesModal(mines)} className={cn.warningMines}>
        ⚠️
      </Button>
    </Tooltip>
  );
};

export { ImportMines };
