import { useMemo } from "react";

import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Column } from "features/tableDebug/simpleTable";

import "dayjs/locale/ru";

import { WellPadsModel } from "../models/mines";

import cn from "../infrastructureParamsTable.module.less";

const columns: Column[] = [
  {
    title: "Кустовая площадка",
    width: { min: 240, max: 270, competitiveness: 1 },
    dataKey: "title",
    type: "string",
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "Скважина",
    width: { min: 240, max: 270, competitiveness: 1 },
    dataKey: "well",
    type: "string",
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "Дата ввода",
    width: { min: 240, max: 270, competitiveness: 1 },
    dataKey: "startedAt",
    onCell: () => ({ className: cn.tableCell }),
    type: "string",
  },
  {
    title: "Координата X, м",
    width: { min: 240, max: 270, competitiveness: 1 },
    dataKey: "x",
    onCell: () => ({ className: cn.tableCell }),
    type: "number",
    editable: (tableItem: any) => !tableItem.value.isFactual,
  },
  {
    title: "Координата Y, м",
    width: { min: 240, max: 270, competitiveness: 1 },
    dataKey: "y",
    onCell: () => ({ className: cn.tableCell }),
    type: "number",
    editable: (tableItem: any) => !tableItem.value.isFactual,
  },
  {
    title: "Альтитуда, м",
    width: { min: 240, max: 270, competitiveness: 1 },
    dataKey: "altitude",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
    type: "number",
    editable: true,
  },
  {
    title: "Стоимость, тыс руб / скв",
    width: { min: 240, max: 270, competitiveness: 1 },
    dataKey: "cost",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
    type: "number",
  },
];

const useParamMinesTableStore = () => {
  const infrastructure = useInfrastructure();
  const store = useMemo(() => new WellPadsModel(infrastructure), [infrastructure]);
  return { columns, store };
};

export { useParamMinesTableStore };
