import type { FC, PropsWithChildren } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { MODE_ICONS } from "elements/modeSelector/modeSelector";
import { ForecastMode } from "services/back/techForecast/request";

import { ReactComponent as StopIcon } from "../icons/stop.svg";
import { Methods } from "../models/well/methods";
import { useTechForecastModel } from "../useTechForecastModel";

import { ApplyForGroupButton } from "./applyForAllButton";

import cn from "./settingsFrame.module.less";

type SettingsFrameProps = PropsWithChildren<{
  mode: ForecastMode | "stopCriterion";
}>;

const TITLE = {
  oil: {
    icon: MODE_ICONS.oilRate,
    title: "Темп падения нефти",
  },
  liquid: {
    icon: MODE_ICONS.liquidRate,
    title: "Темп падения жидкости",
  },
  waterCut: {
    icon: MODE_ICONS.waterCut,
    title: "Прогноз обводнённости",
  },
  stopCriterion: {
    icon: <Icon viewBox="0 0 16 16" content={<StopIcon />} />,
    title: "Критерии остановки",
  },
} as const;

const SettingsFrame: FC<SettingsFrameProps> = observer(({ children, mode }) => {
  const stopCriterion = mode === "stopCriterion";
  const model = useTechForecastModel();
  const fc = model.currentForecast;
  const { icon, title } = TITLE[mode];
  const fitting = mode === "stopCriterion" ? undefined : fc.getFitting(mode);
  const settings = fc.settings[mode];
  return (
    <div className={cn.frame}>
      <div className={cn.title}>
        {icon && <div className={cn.icon}>{icon}</div>}
        <Typography.Title level={3}>{title}</Typography.Title>
        {settings.isValid === false && (
          <Tooltip title={settings.invalidationExplain}>
            <WarningOutlined className={cn.warn} />
          </Tooltip>
        )}
        {settings instanceof Methods && settings.isValid && settings.isCorrectResult === false && (
          <Tooltip title="Расчет проведён но полученный результат не отвечает критериям физичности">
            <WarningOutlined className={cn.warn} />
          </Tooltip>
        )}
        {fitting && (
          <div className={cn.metrics}>
            <span className={cn.label}>R²</span>
            <span className={cn.value}>
              <Format>{fitting.r2}</Format>
            </span>
            <span className={cn.label}>a</span>
            <span className={cn.value}>
              <Format>{fitting.a}</Format>
            </span>
            <span className={cn.label}>MSE</span>
            <span className={cn.value}>
              <Format>{fitting.mse}</Format>
            </span>
            <span className={cn.label}>k</span>
            <span className={cn.value}>
              <Format>{fitting.k}</Format>
            </span>
          </div>
        )}
        {stopCriterion && <ApplyForGroupButton group="stopCriterion" />}
      </div>
      <div className={cn.content}>{children}</div>
    </div>
  );
});

export { SettingsFrame };
