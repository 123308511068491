import dayjs from "dayjs";

import type { WellData } from "services/back/production";

import { aggregateByDate, DateDataRow, DateDataSeries } from "./aggregate";
import { sumUp, sumUpMaxDays } from "./aggregateFunctions";
import { ProductionDatum } from "./production";
import { StratumData } from "./stratumData";

class ProductionDataPack {
  public readonly wellId: number;
  public readonly dataByStratums = new Map<number, StratumData[]>();

  public readonly isMining: boolean = false;
  public readonly isInjecting: boolean = false;

  constructor(wellData: WellData) {
    this.wellId = wellData.wellId;

    for (const stratumData of wellData.stratums) {
      const stratumId = stratumData.stratumId;
      if (stratumData.status === "prod") {
        this.isMining = true;
      }
      if (stratumData.status === "inj") {
        this.isInjecting = true;
      }
      const data = new StratumData(stratumData);
      this.dataByStratums.get(stratumId)?.push(data) || this.dataByStratums.set(stratumId, [data]);
    }
  }

  public *byMonth(): DateDataSeries<ProductionDatum> {
    const iterators = this.all.map((d) => d.byMonth());
    yield* aggregateByDate(sumUpMaxDays, iterators);
  }

  public *byDecember(): DateDataSeries<ProductionDatum> {
    const iterators = this.all.map((d) => d.byDecember());
    yield* aggregateByDate(sumUpMaxDays, iterators);
  }

  public *byYear(): DateDataSeries<ProductionDatum> {
    const [[firstDate, firstDatum], ...rest] = this.byMonth();
    const toSumUp = [firstDatum];
    let currentYear = firstDate.year();
    for (const [date, datum] of rest) {
      if (date.year() !== currentYear) {
        yield [dayjs(`${currentYear}-01-01`), sumUp(toSumUp)];
        toSumUp.length = 0; // clear array
      }
      toSumUp.push(datum);
      currentYear = date.year();
    }
    yield [dayjs(`${currentYear}-01-01`), sumUp(toSumUp)]; // last year
  }

  public get lastMonth(): DateDataRow<ProductionDatum> | null {
    if (this.dataByStratums.size === 0) {
      return null;
    }
    const [first, ...rest] = this.all.map((d) => d.lastMonth);
    let lastDate = first[0];
    let datums: ProductionDatum[] = [first[1]];
    for (const [date, datum] of rest) {
      if (date.isBefore(lastDate)) {
        continue;
      }
      if (date.isAfter(lastDate)) {
        lastDate = date;
        datums.length = 0;
      }
      datums.push(datum);
    }
    return [lastDate, sumUpMaxDays(datums)];
  }

  public getForMonth(year: number, month: number): ProductionDatum | undefined {
    if (this.dataByStratums.size === 0) {
      return undefined;
    }
    const datums = this.all.map((d) => d.getForMonth(year, month)).filter((d): d is ProductionDatum => d !== undefined);
    return sumUpMaxDays(datums);
  }

  public get all(): StratumData[] {
    return [...this.dataByStratums.values()].flat();
  }

  public get mostValuableStratum(): number | undefined {
    if (this.dataByStratums.size === 0) {
      return undefined;
    }
    const [first, ...rest] = this.dataByStratums.entries();
    let resultStratum = first[0];
    let maxDuration = stratumProductionDuration(first[1]);
    for (const [stratumId, data] of rest) {
      const curDuration = stratumProductionDuration(data);
      if (curDuration > maxDuration) {
        resultStratum = stratumId;
        maxDuration = curDuration;
      }
    }
    return resultStratum;
  }

  public get idleMonths(): number {
    let idleMonths: number | undefined;
    for (const [, stratumData] of this.dataByStratums) {
      if (stratumData.length === 0) {
        continue;
      }
      const lastPeriod = stratumData[stratumData.length - 1];
      if (idleMonths === undefined || lastPeriod.range.monthDuration < idleMonths) {
        idleMonths = lastPeriod.range.monthDuration;
      }
    }
    return idleMonths ?? 0;
  }
}

function stratumProductionDuration(data: StratumData[]): number {
  return data.filter((d) => d.status === "prod").reduce((acc, cur) => acc + cur.range.monthDuration, 0);
}

export { ProductionDataPack };
