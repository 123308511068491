import { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Preloader } from "features/preloader/preloader";
import { TechForecast } from "features/techForecast/techForecast";
import { TypeForecastSettings } from "features/techForecast/typeForecastSettings/typeForecastSettings";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";
import { Debet as DebetFeature } from "features/techPrediction/debet";

import { DEBIT_PRELOADER } from "../preloads";

import { DebetContextProvider } from "./context";

const useTabs = (): TabItem[] => {
  const techForecast = useTechForecastModel();
  return useMemo(
    () => [
      {
        key: "results",
        label: "Скважины",
        default: true,
        children: <DebetFeature />,
        disabled: techForecast?.isLoading,
      },
      {
        key: "forecast",
        label: "Прогноз",
        children: <TechForecast />,
      },
      {
        key: "settings",
        label: "Типовые кривые",
        children: <TypeForecastSettings />,
        disabled: techForecast?.isLoading,
      },
    ],
    [techForecast?.isLoading]
  );
};

const Debet = observer(() => {
  const TABS = useTabs();
  return <PageFrame tabs={TABS} title="Прогноз технологических показателей" />;
});

const DebetWrapper: FC = () => (
  <ForecastSelector>
    <Preloader hooks={DEBIT_PRELOADER}>
      <DebetContextProvider>
        <Debet />
      </DebetContextProvider>
    </Preloader>
  </ForecastSelector>
);

export { DebetWrapper as Debet };
