import { useMemo } from "react";
import { TableItem, Widget } from "@okopok/components/Table";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";
import { GenericTableRow } from "services/back/genericTable/genegicTableService";

import { useOperatingGTMContext } from "../operatingGTM";

import cn from "./calcEffectTable.module.less";

const isDisabled = (tableItem: TableItem<GenericTableRow>, cellIndex: number): boolean => {
  if (tableItem.value?.parentDataKey === "miningWells" && !(tableItem.value.dataKey === "miningWells-5" && cellIndex === 0)) {
    return true;
  }
  if (tableItem.value?.parentDataKey === "pumpingWells") {
    return true;
  }
  if (tableItem.value?.title === "Переходящая доп. добыча от ГТМ") {
    return true;
  }
  if (tableItem.value?.title === "Переходящая доп. добыча от ФХ МУН") {
    return true;
  }
  if (tableItem.value?.title === "Коэффициент снижения эффекта ГТМ во времени" && cellIndex < 3) {
    return true;
  }
  if (
    tableItem.value?.parentDataKey &&
    ["otherGTM-s", "otherGTM-p", "otherGTM-pa", "otherGTM-o", "otherGTM-l", "otherGTM-pr", "otherGTM-se"].includes(tableItem.value?.parentDataKey) &&
    tableItem.value.dataKey !== "otherGTM-i" &&
    cellIndex > 2
  ) {
    return true;
  }

  return false;
};

const useColumns = (): Column[] => {
  const forecast = useForecast();

  return useMemo(
    (): Column[] => [
      {
        dataKey: "title",
        title: "Параметр",
        type: "string",
        isSticky: true,
        width: { min: 380, max: 380, competitiveness: 1 },
        hideFilter: true,
        onCell: ({ indexPath }) => ({ style: { paddingLeft: indexPath.length * 15 } }),
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        isSticky: true,
        hideFilter: true,
        type: "string",
        width: { min: 130, max: 130, competitiveness: 1 },
      },
      {
        dataKey: "accuracy",
        title: "Точность",
        isSticky: true,
        type: "number",
        editable: true,
        hideFilter: true,
        width: { min: 80, max: 80, competitiveness: 1 },
      },
      ...[...forecast!.range].slice(1).map(
        (year, index): Column => ({
          dataKey: `${year}`,
          key: `${year}`,
          title: `${year}`,
          type: "number",
          hideFilter: true,
          width: { min: 130, max: 130, competitiveness: 1 },
          onCell: (tableItem) => ({
            className: isDisabled(tableItem, index) && tableItem.expand === undefined ? cn.tableCellLock : "",
          }),
          editable: true,
        })
      ),
    ],
    [forecast]
  );
};

const useCustomRenders = () => {
  const forecast = useForecast();

  return useMemo(() => {
    const renders: Record<string, (tableItem: TableItem<GenericTableRow>, row: any) => JSX.Element | null> = {};

    [...forecast!.range].slice(1).forEach((year, index) => {
      renders[`${year}`] = (value, row) => {
        const yearValue = row?.value[year];

        const calculateValue = (value: TableItem<GenericTableRow>, row: TableItem<any>, yearValue: number) => {
          if (!value || row.value?.accuracy === null) return yearValue;

          if (row.value?.accuracy === 0) {
            return Math.round(yearValue);
          }

          return Number(yearValue?.toFixed(row.value?.accuracy));
        };

        return row.expand === undefined ? (
          isDisabled(row, index) ? (
            <Format>{(row.value?.accuracy && yearValue ? Number(yearValue?.toFixed(row.value?.accuracy)) : yearValue) ?? null}</Format>
          ) : (
            <div className={cn.customInput}>
              <LazyInputNumber
                variant="borderless"
                min={0}
                value={calculateValue(value, row, yearValue)}
                onUpdate={(newValue) => row.update?.(year, newValue)}
              />
            </div>
          )
        ) : null;
      };
    });

    return renders;
  }, [forecast]);
};

const CalcEffectTable = observer(() => {
  const store = useOperatingGTMContext()!.CalcEffectTableModel;

  const columns = useColumns();
  const customRenders = useCustomRenders();

  return (
    <SimpleTableContext
      exportFileName="Расчет эффекта"
      columns={columns}
      data={store}
      tableOptions={{
        onRow: (tableItem) => ({
          className: classNames([
            tableItem.expand === undefined ? cn.tableRowPlain : tableItem.indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          ]),
        }),
      }}
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
      customRenders={customRenders}
    >
      <Widget headerClassName={cn.tableHeader} />
    </SimpleTableContext>
  );
});

export { CalcEffectTable };
