import { useMemo } from "react";
import { Table } from "@okopok/components/Table/Table";
import { DefaultOptionType } from "antd/es/select";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenEmpty, FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Plus } from "elements/icons/plus";
import { PermissionButton } from "elements/permissionButton/permissionButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { ForecastMode } from "services/back/techForecast/request";

import { KNOWN_GROUPS } from "../models/well/wellTechChartChannelsMeta";
import { useTechForecastModel } from "../useTechForecastModel";

import { TypeForecastSettingsModel } from "./typeForecastSettingsModel";

import cn from "./typeForecastSettings.module.less";

const forecastParamOptions = Object.keys(KNOWN_GROUPS).map((el) => ({ value: el, label: KNOWN_GROUPS[el].title }));

const useColumns = (selector: (v: ForecastMode) => DefaultOptionType[]): Column[] => {
  const fact = useFact()!;
  return useMemo(
    (): Column[] => [
      {
        title: "Название",
        dataKey: "title",
        type: "string",
        width: 210,
        editable: true,
      },
      {
        title: "Тип конструкции",
        dataKey: "wellType",
        type: "select",
        width: 210,
        editable: true,
        options: global.wellTypes.selector,
      },
      {
        title: "Объект разработки",
        dataKey: "producingObject",
        type: "select",
        width: 210,
        editable: true,
        options: fact.producingObjects.selector,
      },
      {
        title: "Прогнозируемый параметр",
        dataKey: "forecastParam",
        type: "select",
        width: 210,
        editable: true,
        options: forecastParamOptions,
      },
      {
        title: "Способ расчета",
        dataKey: "method",
        type: "select",
        width: 210,
        editable: true,
        options: (tableItem: any) => selector(tableItem.value.forecastParam ?? "oil"),
      },
      {
        title: "Коэффиент a",
        dataKey: "a",
        type: "number",
        width: 210,
        editable: true,
      },
      {
        title: "Коэффиент k",
        dataKey: "k",
        type: "number",
        width: 210,
        editable: (tableItem: any) => !tableItem.value.isDisabledCoefficientK,
        onCell: (tableItem: any) => ({ className: classNames(tableItem.value.isDisabledCoefficientK && cn.disabled) }),
      },
    ],
    [fact.producingObjects.selector, selector]
  );
};

const TypeForecastSettings = observer(() => {
  const model = useTechForecastModel();
  const forecat = useForecast()!;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new TypeForecastSettingsModel(model.typeForecastSettings), []);
  const columns = useColumns(store.methodSelector);

  if (store.isLoading || forecat.production.isLoading) {
    return <FullScreenLoader />;
  }
  return (
    <SimpleTableContext hideExpandColumn exportFileName="Типовые кривые" columns={columns} data={store}>
      <PageFrameTitlePortal
        model={store ?? undefined}
        onSave={store.submit}
        submitCustomTooltip={{
          onNotUpdated: "Список типовых кривых не изменялся",
          onNotValid: "Требуется заполнить все поля типовой кривой",
        }}
        permissionSection={"prediction"}
        defaultIcon
      >
        <PermissionButton onClick={store.push} icon={<Plus />} section={"prediction"}>
          Добавить типовую кривую
        </PermissionButton>
      </PageFrameTitlePortal>
      {store.childrenStore && store.childrenStore.size === 0 ? (
        <FullScreenEmpty>Нет данных для отображения</FullScreenEmpty>
      ) : (
        <div style={{ height: "100%", marginTop: "8px" }}>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </div>
      )}
    </SimpleTableContext>
  );
});

export { TypeForecastSettings };
