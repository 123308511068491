import { NavigateFunction } from "react-router-dom";
import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { makeAutoObservable, runInAction } from "mobx";

import {
  BalanceData,
  loadResults,
  loadSettings,
  materialBalanceTableView,
  ProducingObjectSettings,
  saveResults,
  saveSettings,
  SettingKey,
  SettingsBody,
} from "services/back/calculate/materialBalance";

import { ProducingObject } from "../producingObject/producingObject";

type Settings = Record<number, ProducingObjectSettings>;

const SETTINGS_INIT: ProducingObjectSettings = {
  cWater: 4.35 / 1e4,
  cOil: 2 / 1e3,
  cPore: 5 / 10e4,
  bOilInit: 1.15,
  bWaterInit: 1,
  oilSaturation: 0.8,
  pReservoirInit: 30,
  oilBearingAreaM2: 10,
  oilReservesInitKg: 326100,
  sRhoOil: 850,
  pSaturation: 10,
  permeability: 197,
  porosity: 0.2,
  saturatedThickness: 10,
  inflowAngle: 6.283,
  waterViscosity: 0.4,
  pAqInit: 30,
};

const SETTINGS_MOCK: Record<string, ProducingObjectSettings> = {
  БВ7: {
    cWater: 4.26 / 1e4,
    cOil: 1.142 / 1e3,
    cPore: 5 / 10e5,
    bOilInit: 1.204,
    bWaterInit: 1,
    oilSaturation: 0.53,
    pReservoirInit: 26.9,
    oilBearingAreaM2: 213429,
    oilReservesInitKg: 24041,
    sRhoOil: 818,
    pSaturation: 8.65,
    permeability: 197,
    porosity: 0.2,
    saturatedThickness: 10,
    inflowAngle: 6.283,
    waterViscosity: 0.4,
    pAqInit: 26.9,
  },
  "ЮВ1/1": {
    cWater: 4.26 / 1e4,
    cOil: 2.67 / 1e3,
    cPore: 5 / 10e5,
    bOilInit: 1.411,
    bWaterInit: 1,
    oilSaturation: 0.46,
    pReservoirInit: 31.1,
    oilBearingAreaM2: 90764,
    oilReservesInitKg: 2265,
    sRhoOil: 829,
    pSaturation: 15.86,
    permeability: 197,
    porosity: 0.2,
    saturatedThickness: 10,
    inflowAngle: 6.283,
    waterViscosity: 0.4,
    pAqInit: 31.1,
  },
};

type DRow = {
  key?: string;
  title: string;
  measure?: string;
  [yearIdx: number]: number | null;
};

type UpdateValue = (producingObjectId: any, value: any) => [prevValue: any, currValue: any];
type GetUpdateValue = (settingKey: SettingKey) => UpdateValue | undefined;

type Setting = {
  key: SettingKey;
  title: string;
  measure?: string;
  children?: Setting[];
};

type ResultKey = "liquidProdM3" | "oilProdM3" | "waterProdM3" | "waterInjM3" | "pressure" | "apgProdM3";

type BalanceResult = {
  key: ResultKey;
  title: string;
  measure?: string;
  [years: number]: number | null;
};

const BALANCE_ROWS_INIT: BalanceResult[] = [
  {
    key: "oilProdM3",
    title: "Добыча нефти",
    measure: "м³",
  },
  {
    key: "waterProdM3",
    title: "Добыча воды",
    measure: "м³",
  },
  {
    key: "liquidProdM3",
    title: "Добыча жидкости",
    measure: "м³",
  },
  {
    key: "waterInjM3",
    title: "Закачка воды",
    measure: "м³",
  },
  {
    key: "pressure",
    title: "Пластовое давление",
    measure: "МПа",
  },
  {
    key: "apgProdM3",
    title: "Добыча газа",
    measure: "м³",
  },
];

const settingsMap: Setting[] = [
  {
    title: "Основные параметры",
    children: [
      { title: "Сжимаемость воды", measure: "1/МПа", key: "cWater" },
      { title: "Сжимаемость нефти", measure: "1/МПа", key: "cOil" },
      { title: "Сжимаемость порового объема", measure: "1/МПа", key: "cPore" },
      { title: "Объемный коэф-т нефти", measure: "д. ед.", key: "bOilInit" },
      { title: "Объемный коэф-т воды", measure: "д. ед.", key: "bWaterInit" },
      { title: "Нач. нефтенасыщенность", measure: "д. ед.", key: "oilSaturation" },
      { title: "Нач. пластовое давление", measure: "МПа", key: "pReservoirInit" },
      { title: "Площадь нефтеносности", measure: "тыс. м²", key: "oilBearingAreaM2" },
      { title: "Нач. геологические запасы нефти", measure: "тыс. т", key: "oilReservesInitKg" },
      { title: "Плотность нефти", measure: "кг/м²", key: "sRhoOil" },
    ],
    key: null,
  },
  {
    title: "Параметры аквифера",
    children: [
      { title: "Проницаемость", measure: "мД", key: "permeability" },
      { title: "Пористость", measure: "д. ед.", key: "porosity" },
      { title: "Водонасыщенная толщина", measure: "м", key: "saturatedThickness" },
      { title: "Угол притока", measure: "град.", key: "inflowAngle" },
      { title: "Вязкость воды", measure: "мПа*с", key: "waterViscosity" },
      { title: "Нач. давление в аквифере", measure: "МПа", key: "pAqInit" },
    ],
    key: null,
  },
];

class MaterialBalanceRow extends TableNode<DRow, MaterialBalanceRow> {
  public asDRow = (): DRow => ({
    title: this.setting.title,
    measure: this.setting.measure,
    ...this.settingsData,
  });
  constructor(
    parent: MaterialBalanceStore | MaterialBalanceRow,
    private setting: Setting,
    private producingObjects: ProducingObject[],
    private balanceModel: MaterialBalance
  ) {
    super(parent, { isExpandedChildren: true });
    runInAction(
      () =>
        (this.childrenStore = this.setting.children
          ? new ChildrenStoreArray(
              this,
              this.setting.children.map(
                (setting) => new MaterialBalanceRow(this, setting, this.producingObjects, balanceModel)
              )
            )
          : null)
    );
  }

  public readonly updateValue = this.balanceModel.getUpdateValue(this.setting.key);

  get settingsData() {
    const res: Record<number, number> = {};

    for (const producingObject of this.producingObjects) {
      if (this.setting.key) {
        res[producingObject.id] = this.balanceModel.settings[producingObject.id][this.setting.key];
      }
    }
    return res;
  }
}

class MaterialBalanceStore extends TableNode<DRow, MaterialBalanceRow> {
  constructor(private producingObjects: ProducingObject[], private balanceModel: MaterialBalance) {
    super();
    this.init();
  }

  public init = () => {
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        settingsMap.map((setting) => new MaterialBalanceRow(this, setting, this.producingObjects, this.balanceModel))
      );
    });
  };
}

class MaterialBalanceResultRow extends TableNode<DRow, MaterialBalanceResultRow> {
  public asDRow = (): DRow => ({
    ...this.balanceResultsRow,
  });
  constructor(parent: MaterialBalanceResultStore | MaterialBalanceResultRow, private balanceResultsRow: DRow) {
    super(parent);
  }
}

class MaterialBalanceResultStore extends TableNode<DRow, MaterialBalanceResultRow> {
  constructor(private results: BalanceResult[]) {
    super();
    this.init();
  }

  public init = () => {
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        this.results.map((row) => new MaterialBalanceResultRow(this, row))
      );
    });
  };
}

class MaterialBalance {
  store: MaterialBalanceStore;
  resultsStores: Record<number, MaterialBalanceResultStore> = {};
  settings: Settings = {};
  results: Record<number, BalanceResult[]> = {};
  isCalculating: boolean = false;
  currentProdId: number;
  isLoadingSettings: boolean = false;
  isLoadingResults: boolean = false;

  constructor(
    public readonly producingObjects: ProducingObject[],
    private projectId: number,
    private scenarioId: number,
    private navigate: NavigateFunction
  ) {
    this.currentProdId = producingObjects[0].id;
    const lastId = producingObjects[producingObjects.length - 1].id;
    this.isLoadingSettings = true;
    this.isLoadingResults = true;
    for (const producingObject of this.producingObjects) {
      loadSettings(projectId, scenarioId, producingObject.id).then((settings) => {
        if (settings) {
          this.settings[producingObject.id] = settings;
        } else if (SETTINGS_MOCK[producingObject.title]) {
          this.settings[producingObject.id] = SETTINGS_MOCK[producingObject.title];
        } else {
          this.settings[producingObject.id] = SETTINGS_INIT;
        }
        if (producingObject.id === lastId) {
          this.isLoadingSettings = false;
        }
      });

      loadResults(projectId, scenarioId, producingObject.id).then((results) => {
        if (results) {
          this.results[producingObject.id] = results;
          this.resultsStores[producingObject.id] = new MaterialBalanceResultStore(results);
        }
        if (producingObject.id === lastId) {
          this.isLoadingResults = false;
        }
      });
    }
    this.store = new MaterialBalanceStore(producingObjects, this);
    makeAutoObservable(this);
  }

  getUpdateValue: GetUpdateValue = (settingKey) => {
    if (settingKey) {
      return (producingObjectId, value) => {
        const prevValue = this.settings[producingObjectId][settingKey];
        this.settings[producingObjectId][settingKey] = value;
        return [prevValue, value];
      };
    }
  };

  calculate = async () => {
    this.isCalculating = true;
    for (const [producingObjectId, producingObjectSettings] of Object.entries(this.settings)) {
      const producingObjectIdNum = Number(producingObjectId);

      const reqBody: SettingsBody = {
        producingObjectId: producingObjectIdNum,
        scenarioId: this.scenarioId,
        monthCount: 1,
        deposit: {
          ...producingObjectSettings,
          cWater: producingObjectSettings.cWater / 1e6,
          cOil: producingObjectSettings.cOil / 1e6,
          cPore: producingObjectSettings.cPore / 1e6,
          pReservoirInit: producingObjectSettings.pReservoirInit * 1e6,
          oilReservesInitKg: producingObjectSettings.oilReservesInitKg * 1e6,
          pSaturation: producingObjectSettings.pSaturation * 1e6,
          permeability: producingObjectSettings.permeability / 1e15,
          waterViscosity: producingObjectSettings.waterViscosity / 1e3,
          pAqInit: producingObjectSettings.pAqInit * 1e6,
          inflowAngle: (producingObjectSettings.inflowAngle * Math.PI) / 180,
        },
        convergence: {
          iterCount: 5,
          relTol: 0.1,
        },
      };
      const producingObjectResult = await materialBalanceTableView(reqBody);
      this.results[producingObjectIdNum] = this.balanceRowsFromData(producingObjectResult.data);
      this.resultsStores[producingObjectIdNum] = new MaterialBalanceResultStore(this.results[producingObjectIdNum]);
    }
    this.isCalculating = false;
    this.navigate(`/${this.projectId}/${this.scenarioId}/balance/results`);
  };

  balanceRowsFromData = (balanceData: BalanceData[]): BalanceResult[] => {
    const data = balanceData[0].children!;

    const res: BalanceResult[] = BALANCE_ROWS_INIT;
    for (const yearData of data) {
      for (const row of res) {
        if (row.key === "pressure") {
          const v = yearData[row.key];
          row[Number(yearData.date)] = v ? v / 10 : null;
        } else {
          row[Number(yearData.date)] = yearData[row.key] ?? null;
        }
      }
    }
    return res;
  };

  get resultsStore(): MaterialBalanceResultStore {
    return this.resultsStores[this.currentProdId];
  }

  get result(): BalanceResult[] {
    return this.results[this.currentProdId];
  }

  setProdId = (id: number) => {
    this.currentProdId = id;
  };

  saveProdObjectSettings = (settings: ProducingObjectSettings, producingObjectId: number) =>
    saveSettings(settings, this.projectId, this.scenarioId, producingObjectId);
  saveProdObjectResults = (results: BalanceResult[], producingObjectId: number) =>
    saveResults(results, this.projectId, this.scenarioId, producingObjectId);

  saveSettings = async () => {
    for (const [prodObjectId, settings] of Object.entries(this.settings)) {
      await this.saveProdObjectSettings(settings, Number(prodObjectId));
    }
  };

  saveResults = async () => {
    for (const [prodObjectId, results] of Object.entries(this.results)) {
      await this.saveProdObjectResults(results, Number(prodObjectId));
    }
  };
}

export { MaterialBalance };
export type { BalanceResult };
