import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { DefaultOptionType } from "antd/es/select";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { ForecastMode, methodSelector } from "services/back/techForecast/request";
import { isNeedK } from "services/back/techForecast/request";
import { TypeForecastSetting } from "services/back/techForecast/typeSettings";
import { conditionallyArr } from "utils/conditionally";
import { getRandomUid } from "utils/random";

import { TypeForecastSettings } from "../models/typeForecastSettings";

type DRow = TypeForecastSetting & {
  isDisabledCoefficientK: boolean;
  remove?: () => void;
};

class TypeForecastSettingModel extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data!,
    k: !isNeedK(this.data.method!) ? null : this.data.k,
    isDisabledCoefficientK: !isNeedK(this.data.method!),
    remove: this.remove,
  });
  public data: TypeForecastSetting;
  constructor(private parent: TypeForecastSettingsModel, data: TypeForecastSetting) {
    super(parent);

    makeObservable(this, { data: observable, updateValue: action, isValid: computed });

    this.data = data;
  }

  public get isUpdated(): boolean {
    return this.parent.isUpdated;
  }

  public get isValid(): boolean {
    return this.parent.isValid;
  }

  updateValue(key: keyof TypeForecastSetting, newValue: never): [prevValue: any, currValue: any] {
    if (TypeForecastSettingModel.isCompoundKey(key)) {
      const prev = this.data![key];
      this.data![key] = newValue;
      this.parent.typeForecastSettings.update({ uuid: this.data.uuid, [key]: newValue });
      if (key === "forecastParam" && prev !== newValue) {
        const methods = methodSelector(newValue).map((el) => el.value);
        if (!methods.includes(this.data.method as (typeof methods)[number])) {
          this.data.method = null;
        }
      }
      return [prev, newValue];
    }
    return [undefined, undefined];
  }

  static isCompoundKey(key: keyof DRow) {
    return ["title", "wellType", "producingObject", "forecastParam", "method", "a", "k"].includes(key);
  }

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.parent.childrenStore?.splice(this.index, 1);
    this.parent.typeForecastSettings.remove(this.data.uuid);
  };

  public static createSetting = (): TypeForecastSetting => {
    return {
      uuid: getRandomUid(),
      title: null,
      wellType: null,
      producingObject: null,
      forecastParam: null,
      method: null,
      a: null,
      k: null,
    };
  };
}

class TypeForecastSettingsModel extends TableNode<DRow, TypeForecastSettingModel> {
  constructor(public typeForecastSettings: TypeForecastSettings) {
    super();

    this.initChildren(typeForecastSettings.data ?? []);
    reaction(
      () => typeForecastSettings.data?.length,
      () => this.initChildren(typeForecastSettings.data ?? [])
    );
  }

  get isLoading() {
    return super.isLoading || this.typeForecastSettings.isLoading;
  }

  get isUpdated() {
    return this.typeForecastSettings.isUpdated;
  }

  public get isValid(): boolean {
    if (!this.typeForecastSettings.data) {
      return false;
    }

    return this.typeForecastSettings.data.every((setting) => {
      const valuesToCheck = Object.entries(setting).filter(([key]) => {
        return !(key === "k" && !isNeedK(setting.method!));
      });

      return valuesToCheck.every(([, value]) => value !== null && value !== undefined && value !== "");
    });
  }

  public methodSelector(mode: ForecastMode): DefaultOptionType[] {
    return [
      ...methodSelector(mode),
      ...conditionallyArr(mode === "oil", {
        value: "wellsAnalog",
        label: "Скважины аналоги",
      }),
    ];
  }

  private initChildren = (data: TypeForecastSetting[]) => {
    this.childrenStore = new ChildrenStoreArray(
      this,
      data.map((el) => new TypeForecastSettingModel(this, el))
    );
  };

  public push = () => {
    const newSetting = TypeForecastSettingModel.createSetting();
    this.childrenStore?.push(new TypeForecastSettingModel(this, newSetting));
    this.typeForecastSettings.addSetting(newSetting);
  };

  public submit = () => {
    this.typeForecastSettings.save();
  };
}

export { type DRow, TypeForecastSettingsModel };
