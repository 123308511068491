import { FC, PropsWithChildren, useEffect, useState } from "react";

const DelayedShow: FC<PropsWithChildren<{ delay: number }>> = ({ delay, children }) => {
  const [isShown, setShown] = useState(false);

  useEffect(() => {
    setTimeout(() => setShown(true), delay);
  }, [delay]);

  if (isShown === false) {
    return null;
  }

  return <>{children}</>;
};

export { DelayedShow };
