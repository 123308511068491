import { action, computed, makeObservable, observable, override, runInAction, transaction, when } from "mobx";

import { Lazy } from "models/lazy";
import {
  AllApproximations,
  Analogs as AnalogsType,
  BindingMode,
  Excel,
  FITTING_RESULT_MAP,
  ForecastMode,
  isNeedK,
  isScalarApproximation,
  isVectorApproximation,
  NEED_K,
  PARAM_META,
  SCALAR_APPROXIMATIONS,
  ScalarApproximations,
  VECTOR_APPROXIMATIONS,
  VectorApproximations,
} from "services/back/techForecast/request";
import { TypeForecastSetting } from "services/back/techForecast/typeSettings";
import { conditionally } from "utils/conditionally";

import { Analogs as AnalogsModel } from "./analogs";
import type { WellTechForecast } from "./wellTechForecast";

type ForecastMethod = AllApproximations | "wellsAnalog" | "displacementCharacteristics" | "typical" | "excel";

type Scalars = Record<
  ScalarApproximations,
  {
    a: number | null;
    aPercentType: "month" | "year";
    k?: number | null;
  }
>;

type Vectors = Record<
  string,
  {
    x: number[] | null;
    y: number[] | null;
  }
>;

type ScalarDump = { fnType: ScalarApproximations; a?: number | null; k?: number | null };
type VectorDump = { fnType: VectorApproximations; x: number[] | null; y: number[] | null };

const isScalarDump = (dump: ScalarDump | VectorDump | Excel): dump is ScalarDump =>
  "fnType" in dump && isScalarApproximation(dump.fnType) && !("x" in dump);

const isVectorDump = (dump: ScalarDump | VectorDump | Excel): dump is VectorDump => "fnType" in dump && "x" in dump;

const isExcelDump = (dump: ScalarDump | VectorDump | Excel): dump is Excel => "values" in dump;

type PercentType = "month" | "year";

type MethodsSave = {
  method: ForecastMethod;
  factDomain: { min: number; max: number } | null;
  emptyDots: number[];
  binding: "extrapolate" | "last_fact" | number;
  scalars: Array<[keyof Scalars, Scalars[keyof Scalars]]>;
  vectors: Array<[keyof Vectors, Vectors[keyof Vectors]]>;
  analogs: AnalogsType | null;
  typical: string | null;
};

class Methods {
  public method: ForecastMethod = "geometric_progression";
  // null если не прогноз факта или факт не грузился
  public factDomain: { min: number; max: number } | null | undefined;
  public readonly emptyDots = observable.set<number>();
  public binding: "extrapolate" | "last_fact" | number = "last_fact";
  // обращение только в разыменованном виде через геттер или через холдер
  private typicalValue: null | string = null;
  get typical(): TypeForecastSetting | undefined | null {
    if (this.typicalValue === null) {
      return null;
    }
    return this.fc.manager.typeForecastSettings.at(this.typicalValue);
  }
  get typicalUid(): string | null | undefined {
    const { typical } = this;
    return typical === null || typical === undefined ? typical : this.typicalValue;
  }

  private excel: number[] | null = null;

  private readonly scalars: Scalars;

  public readonly vectors: Vectors = Object.fromEntries(
    Array.from(VECTOR_APPROXIMATIONS.values(), (key) => [
      key,
      {
        x: null,
        y: null,
      },
    ])
  ) as Vectors;

  #analogs = new Lazy(() => (this.mode !== "waterCut" ? new AnalogsModel(this.mode, this.fc) : null));

  public percentType: PercentType = "month";

  public setPercentType = (type: PercentType) => {
    this.percentType = type;
  };

  constructor(private readonly mode: ForecastMode, private readonly fc: WellTechForecast) {
    const { group } = fc;
    this.binding = group === "base" ? "last_fact" : fc.initialBinding(mode);
    this.scalars = Object.fromEntries(
      Array.from(SCALAR_APPROXIMATIONS.values() as Iterable<ScalarApproximations>, (key) => [
        key,
        {
          a: group === "base" ? null : PARAM_META[key].a.default[this.mode],
          ...conditionally(key === "hyperbolic", {
            k: group === "base" ? null : PARAM_META.hyperbolic.k.default[mode],
          }),
        },
      ])
    ) as Scalars;

    makeObservable<Methods, "scalars" | "typicalValue" | "excel">(this, {
      factDomain: observable,
      method: observable,
      scalars: observable,
      vectors: observable,
      binding: observable,
      typicalValue: observable,
      excel: observable,
      percentType: observable,
      bindingValue: computed,
      bindingMode: computed,
      applyDump: action,
      setPercentType: action,
      toSave: computed,
      typical: computed,
      typicalUid: computed,
      fromSave: action,
      isNeedBounds: computed,
    });
  }

  static inheritedObservable = {
    factDomain: override,
    method: override,
    scalars: override,
    vectors: override,
    binding: override,
    bindingValue: override,
    bindingMode: override,
    applyDump: override,
    analogs: override,
    toSave: override,
    fromSave: override,
  };

  get bindingMode(): BindingMode {
    return typeof this.binding === "number" || this.fc.fact.isStoppedWell ? "manual" : this.binding;
  }

  readonly typicalHolder = (value: string) => {
    runInAction(() => {
      this.typicalValue = value;
    });
  };

  readonly bindingModeHolder = (value: BindingMode) =>
    runInAction(() => {
      if (value === "manual") {
        when(() => this.fc.fact.isLoading === false).then(() =>
          runInAction(() => {
            this.binding = this.fc.lastFactBinding(this.mode)!;
          })
        );
      } else {
        this.binding = value;
      }
    });

  get bindingValue(): number | null {
    return typeof this.binding === "number" ? this.binding : null;
  }

  readonly bindingValueHolder = (value: number | null) => {
    if (value !== null) {
      this.binding = value;
    }
  };

  get asTypical() {
    const { well } = this.fc.selection;
    if (this.method === "wellsAnalog") {
      return {
        wellType: well.type,
        producingObject: well.producingObject?.title,
        method: "geometric_progression",
        a: this.analogs.a,
        k: null,
      };
    }
    if (!isScalarApproximation(this.method)) {
      return null;
    }
    const currentResult = this.fc.currentResult?.data?.[FITTING_RESULT_MAP[this.mode]];
    return {
      wellType: well.type,
      producingObject: well.producingObject?.title,
      method: this.method,
      a: this.a ?? currentResult?.a ?? null,
      k: isNeedK(this.method) ? this.k ?? currentResult?.k ?? null : null,
    };
  }

  get a(): number | null {
    if (!isScalarApproximation(this.method)) {
      console.error(`a getter requested. Method ${this.method} must be a scalar`);
      return null;
    }
    return this.scalars[this.method].a;
  }

  get k(): number | null {
    if (!isNeedK(this.method)) {
      console.error(`k getter requested. Method ${this.method} must be a ${[...NEED_K.entries()].join()}`);
      return null;
    }
    const result = this.scalars[this.method].k;
    console.assert(result !== undefined, `undefined значение для значения k методе ${this.method}`);
    return result as null | number;
  }

  readonly aHolder = (value: number | null | undefined) => {
    runInAction(() => {
      if (!isScalarApproximation(this.method)) {
        console.error(`aHolder has no effect. Method ${this.method} must be a scalar`);
        return;
      }
      this.scalars[this.method].a = value ?? null;
    });
  };

  readonly kHolder = (value: number | null) => {
    runInAction(() => {
      if (!isNeedK(this.method)) {
        console.error(`aHolder has no effect. Method ${this.method} must be a ${[...NEED_K.entries()].join()}`);
        return;
      }
      this.scalars[this.method].k = value;
    });
  };

  readonly vectorHolder = (value: { x: number[]; y: number[] }) => {
    runInAction(() => {
      this.vectors[this.method] = value;
    });
  };

  readonly excelHolder = (value: number[]) => {
    runInAction(() => {
      this.excel = value;
    });
  };

  readonly methodSelectionHolder = (selection: any) =>
    runInAction(() => {
      this.method = selection as ForecastMethod;
    });

  get isNeedBounds(): boolean {
    if (this.method === "excel" || this.method === "wellsAnalog") {
      return false;
    }
    if (this.method === "geometric_progression" && this.a !== null) {
      return false;
    }
    return true;
  }

  get dump() {
    if (isScalarApproximation(this.method)) {
      const { percentType } = this;
      const a = this.a !== null && percentType === "year" ? Math.abs(Math.pow(1 - this.a, 1 / 12) - 1) : this.a;
      return {
        fnType: this.method,
        ...(isNeedK(this.method)
          ? conditionally(a !== null && this.k !== null, { a, k: this.k })
          : conditionally(a !== null, { a })),
      } as ScalarDump;
    } else if (isVectorApproximation(this.method)) {
      // иначе это векторные параметры обводнённости или ручное задание прогноза
      return {
        fnType: this.method as any,
        ...this.vectors[this.method],
      } as VectorDump;
    }
    if (this.method === "wellsAnalog") {
      return this.analogs.dump;
    }
    if (this.method === "typical") {
      const typical = this.typical;
      if (typical === null || typical === undefined) {
        return {
          fnType: "geometric_progression",
        } as ScalarDump;
      }
      const result: ScalarDump = {
        fnType: typical.method! as ScalarApproximations,
        a: typical.a,
        ...conditionally(typical.k !== null, { k: typical.k }),
      };
      return result;
    }
    if (this.method === "excel") {
      return {
        values: this.excel,
      };
    }
    console.error(`critical state analyse fail ${this.method}`);
    return null as never;
  }

  get toSave(): MethodsSave {
    return {
      method: this.method,
      factDomain: this.factDomain ?? null,
      emptyDots: [...this.emptyDots.values()],
      binding: this.binding,
      scalars: [...(Object.entries(this.scalars) as MethodsSave["scalars"])],
      vectors: [...(Object.entries(this.vectors) as MethodsSave["vectors"])],
      analogs: this.method === "wellsAnalog" ? this.analogs.save : null,
      typical: this.typicalValue,
    };
  }

  get analogs(): AnalogsModel {
    console.assert(this.mode !== "waterCut", "Обращение к аналогам в режиме прогноза по жидкости");
    return this.#analogs.value!;
  }

  fromSave(data: MethodsSave) {
    transaction(() => {
      this.method = data.method;
      this.factDomain = data.factDomain;
      this.emptyDots.replace(observable.set(data.emptyDots));
      this.binding = data.binding;
      this.typicalValue = data.typical;
      if (data.analogs !== null) {
        this.analogs.fromSave(data.analogs);
      }
      for (const [key, val] of data.scalars) {
        this.scalars[key as ScalarApproximations] = val;
      }
      for (const [key, val] of data.vectors) {
        this.vectors[key as VectorApproximations] = val;
      }
    });
  }

  applyDump(value: ScalarDump | VectorDump) {
    this.method = value.fnType;
    if (isScalarDump(value)) {
      this.aHolder(value.a);
      if (value.k) {
        this.kHolder(value.k);
      }
    } else {
      this.vectors[value.fnType] = {
        x: value.x,
        y: value.y,
      };
    }
  }

  get isValid(): boolean {
    return this.invalidationExplain === null;
  }

  get isCorrectResult(): boolean {
    return (
      this.fc.currentResult?.data?.forecastProduction?.[
        (
          {
            liquid: "isCorrectLiquidRateM3",
            oil: "isCorrectOilRateT",
            waterCut: "isCorrectWaterCutVol",
          } as const
        )[this.mode]
      ] !== false
    );
  }

  get invalidationExplain(): string | null {
    const { dump } = this;
    if (dump === null) {
      return "Ошибка при построении запроса (метод прогнозирования реализован не полностью и расчет произвести невозможно";
    }
    if (this.method === "typical") {
      if (this.typicalValue === null) {
        return "Укажите типовую кривую";
      }
      if (!("fnType" in dump) || !isScalarDump(dump)) {
        return "При задании типовой кривой была допущена ошибка";
      }
      return null;
    }
    if (isExcelDump(dump)) {
      if (dump.values === null) {
        return "Необходимо выбрать файл для загрузки";
      }
      return null;
    }
    if (isScalarDump(dump)) {
      if (this.fc.fact.isStoppedWell && (typeof this.binding !== "number" || this.binding === 0)) {
        return "Для остановленной скважины требуется указать стартовый дебит, если прогнозируется возобновление добычи";
      }
      if (typeof dump.a === "number" && (typeof dump.k === "number" || !isNeedK(dump.fnType))) {
        return null;
      }
      if (this.method === "wellsAnalog") {
        return "Требуется выполнить подбор аналогов для текущих параметров";
      } else {
        if (this.fc.group === "base") {
          return null;
        }
        return "Требуется указать параметры прогноза";
      }
    }
    if (this.fc.group === "base") {
      return null;
    }
    if (isVectorDump(dump)) {
      if (Array.isArray(dump.x) && Array.isArray(dump.y)) {
        return null;
      }
      return "Укажите файлы для составления прогноза";
    }
    console.error(`critical state analyze fail ${this.method}`);
    return null;
  }
}

export { type ForecastMethod, isScalarDump, Methods, type MethodsSave, type ScalarDump };
