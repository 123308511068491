import { PageFrame } from "routing/pageFrame/pageFrame";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { DashboardTable } from "features/dashboard/dashboard";
import { Preloader } from "features/preloader/preloader";
import { useProjects } from "models/project/projects";
import { isAdminRole } from "services/back/roles";

const Dashboard = () => {
  return isAdminRole() ? (
    <PageFrame title="Администрирование системы">
      <Preloader hooks={[useProjects]}>
        <DashboardTable />
      </Preloader>
    </PageFrame>
  ) : (
    <FullScreenEmpty>Недостаточно прав для просмотра страницы</FullScreenEmpty>
  );
};

export { Dashboard };
