import { TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";

import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import { ProducingObject } from "models/project/producingObject/producingObject";
import type { WellParsedRaw, WellUnsaved } from "services/back/wells";

import { Fact } from "../../fact";
import { Forecast } from "../../forecast/forecast";
import { WellPad } from "../../wellPad/wellPad";
import { Well } from "../well";

import { DRow } from "./listFlat";
import { WellNodeFactual } from "./wellNodeFactual";

type WellNodeContainer = TableNode<DRow, WellNode | WellNodeFactual> & {
  fact?: Fact;
  forecast: Forecast | null;
  modeModel?: ModeSelectorModel;
  propagateDuplicates?: () => void;
};

// Merge WellParsedRaw and WellRaw
type WellRawOptional = {
  well_id: number | null;

  title: string | null;
  date: string | null;
  isRankingResultDate: boolean;

  stratumId: number | null;
  stratumTitle: string | null;

  mineId: number | null;
  mineTitle: string | null;

  producingObjectId: number | null;
  producingObjectTitle: string | null;

  typeId: number | null;
  typeTitle: string | null;

  licenseZoneId: number | null;
  licenseZoneTitle: string | null;

  // Устье
  topX: number | null;
  topY: number | null;

  // Забой
  botX: number | null;
  botY: number | null;

  md: number | null;
  oilRate: number | null;
  liquidRate: number | null;
  waterCut: number | null;
  recoverableResources: number | null;
  notEditable?: boolean;
};

class WellNode extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    title: this.data.title ?? "",
    wellId: this.data.well_id,
    date: this.data.date,
    isRankingResultDate: this.data.isRankingResultDate,

    type: [this.data.typeId, this.data.typeTitle ?? undefined],
    stratum: [this.data.stratumId, this.data.stratumTitle ?? undefined],
    mine: [this.data.mineId, this.data.mineTitle ?? undefined],
    licenseZone: [this.data.licenseZoneId, this.data.licenseZoneTitle ?? undefined],
    producingObject: [this.data.producingObjectId, this.data.producingObjectTitle ?? undefined],

    topX: this.data.topX,
    topY: this.data.topY,
    botX: this.data.botX,
    botY: this.data.botY,

    md: this.data.md,
    oilRate: this.data.oilRate,
    liquidRate: this.data.liquidRate,
    waterCut: this.data.waterCut,
    recoverableResources: this.data.recoverableResources,

    isComplete: this.isComplete,
    isDuplicatedWell: this.isDuplicatedWell,
    notEditable: false,

    copy: this.copy,
    remove: this.remove,
  });

  public data: WellRawOptional;
  private mine: WellPad | null = null;
  public isDuplicatedWell: boolean = false;

  constructor(public readonly parent: WellNodeContainer, data?: Well | WellParsedRaw) {
    super(parent);
    makeObservable<WellNode, "mine">(this, {
      data: observable,
      mine: observable.ref,
      isDuplicatedWell: observable,
      initMine: action,
      updateValue: action,
      producingObject: computed,
      toWellUnsaved: computed,
      isComplete: computed,
      isCompleted: computed,
    });

    const fcStartDate = parent.forecast?.fact.forecastDateRange.from ?? undefined;
    this.data =
      data !== undefined
        ? data instanceof Well
          ? WellNode.wellToRawOptional(data)
          : WellNode.parsedToRawOptional(data)
        : WellNode.defaultRawOptional(fcStartDate);
    this.initMine();

    runInAction(() => {
      this.childrenStore = null;
    });
    reaction(
      () => [this.modeModel?.mode, this.data.producingObjectId],
      () => this.onModeReset()
    );
  }

  private onModeReset() {
    if (this.modeModel === null || !this.producingObject) {
      return;
    }
    const mode = this.modeModel.mode;
    const args: Record<typeof mode, [number | null, number | null]> = {
      oilRate: [this.data.liquidRate, this.data.waterCut],
      liquidRate: [this.data.oilRate, this.data.waterCut],
      waterCut: [this.data.liquidRate, this.data.oilRate],
    };
    if (args[mode].includes(null)) {
      return;
    }
    const newValue = this.modeModel[`${mode}Calc`](
      ...(args[mode] as [number, number]),
      this.producingObject.oilRelativeDensity
    );
    this.mutationsManager?.updateWrapper(mode, newValue);
  }

  public initMine() {
    if (this.data.mineId !== null) {
      this.mine = this.parent.forecast?.wellPads.at(this.data.mineId) ?? null;
    } else if (this.data.mineTitle !== null) {
      this.mine = this.parent.forecast?.wellPads.createPad(this.data.mineTitle) ?? null;
      this.data.mineId = this.mine?.id ?? null;
    } else {
      this.mine = null;
    }
  }

  public get producingObject(): ProducingObject | null | undefined {
    if (this.data.producingObjectId === null) {
      return null;
    }
    const { fact, forecast } = this.parent;
    return (fact ?? forecast?.fact)?.producingObjects.at(this.data.producingObjectId);
  }

  public get modeModel(): ModeSelectorModel | null {
    return this.parent.modeModel ?? null;
  }

  public get isComplete(): boolean {
    const { title, date, mineId, producingObjectId, recoverableResources } = this.data;
    return ![title, date, mineId, producingObjectId, recoverableResources].includes(null);
  }

  public get isCompleted(): boolean {
    const {
      title,
      date,
      mineId,
      producingObjectId,
      recoverableResources,
      topX,
      topY,
      botX,
      botY,
      oilRate,
      liquidRate,
      waterCut,
      md,
    } = this.data;
    return ![
      title,
      date,
      mineId,
      producingObjectId,
      recoverableResources,
      topX,
      topY,
      botX,
      botY,
      oilRate,
      liquidRate,
      waterCut,
      md,
    ].includes(null);
  }

  static fromRaw(parent: WellNodeContainer, data: WellRawOptional): WellNode {
    const node = new WellNode(parent);
    runInAction(() => {
      node.data = data;
    });
    node.initMine();
    return node;
  }

  public get toWellUnsaved(): WellUnsaved | undefined {
    if (!this.isCompleted || this.isDuplicatedWell) {
      return undefined;
    }
    let mineId;
    if (this.data.mineId! < 0) {
      if (this.mine?.isUnsaved === false) {
        mineId = this.mine.id;
      } else {
        console.error("Подготовка к сохранению скважины с несуществующим кустом");
        return undefined;
      }
    } else {
      mineId = this.data.mineId!;
    }
    const stratumId = this.producingObject?.data.mainStratumId;
    if (stratumId === null || stratumId === undefined) {
      return undefined;
    }
    return {
      id: this.data.well_id ?? undefined,
      title: this.data.title!,
      date: this.data.date,
      isRankingResultDate: this.data.isRankingResultDate,
      mineId,
      stratumId,
      wellTypeId: this.data.typeId,
      md: this.data.md,
      topX: this.data.topX,
      topY: this.data.topY,
      botX: this.data.botX,
      botY: this.data.botY,
      oilRate: this.data.oilRate,
      liquidRate: this.data.liquidRate,
      waterCut: this.data.waterCut,
      recoverableResources: this.data.recoverableResources,
      licenseZoneId: this.data.licenseZoneId,
    };
  }

  protected remove = () => {
    if (this.index === undefined) {
      console.error("удаление скважины без индекса");
      return;
    }
    this.parentNode?.childrenStore?.splice(this.index, 1);
    this.parent.propagateDuplicates?.();
  };

  protected copy = () => {
    if (this.index === undefined) {
      console.error("копирование скважины без индекса");
      return;
    }

    const { well_id, ...dataCopy } = this.data;
    const well = new WellNode(this.parent);
    well.data = { well_id: null, ...dataCopy };
    this.parent?.childrenStore?.splice(this.index + 1, 0, well);
    this.parent.propagateDuplicates?.();
  };

  public updateValue(key: keyof DRow, newValue: any): [prevValue: any, currValue: any] {
    if (
      key === "copy" ||
      key === "remove" ||
      key === "isComplete" ||
      key === "isDuplicatedWell" ||
      key === "wellId" ||
      key === "isRankingResultDate" ||
      key === "notEditable"
    ) {
      console.error("attempt to update not editable field");
      return [undefined, undefined];
    }
    if (WellNode.isCompoundKey(key)) {
      const prev: number | null = this.data[`${key}Id`];
      const curr: number | null = (this.data[`${key}Id`] = newValue[0]);
      this.data[`${key}Title`] = newValue[1] ?? null;
      if (key === "mine") {
        if (curr === null) {
          this.mine = null;
        } else {
          this.mine = this.parent.forecast?.wellPads.at(curr) ?? null;
        }
      } else if (key === "producingObject") {
        this.data["stratumId"] = this.producingObject?.data.mainStratumId ?? null;
      }
      return [prev, curr];
    }
    if (key === "title") {
      const prev = this.data[key];
      this.data[key] = newValue;
      this.parent.propagateDuplicates?.();
      return [prev, (this.data[key] = newValue)];
    }

    if (key === "date") {
      const prev = this.data[key];
      this.data[key] = newValue.isValid?.() ? newValue.format("YYYY-MM-DD") : null;
      this.data.isRankingResultDate = false;
      return [prev, this.data[key]];
    }

    const prev = this.data[key];
    this.data[key] = newValue;

    if ((key === "oilRate" || key === "liquidRate" || key === "waterCut") && this.producingObject) {
      this.modeModel
        ?.onValueChange(
          key,
          this.data["oilRate"],
          this.data["liquidRate"],
          this.data["waterCut"],
          this.producingObject.oilRelativeDensity
        )
        .forEach(({ key, value }) => {
          this.mutationsManager?.mutations.mutate(key, this.data[key], value);
          this.data[key] = value;
        });
    }

    return [prev, this.data[key]];
  }

  static isCompoundKey(key: keyof DRow): key is "mine" | "stratum" | "producingObject" | "type" | "licenseZone" {
    return ["mine", "stratum", "producingObject", "type", "licenseZone"].includes(key);
  }

  static defaultRawOptional(date?: Dayjs): WellRawOptional {
    return {
      well_id: null,
      title: null,
      date: date?.startOf("month").format("YYYY-MM-DD") ?? null,
      isRankingResultDate: false,

      stratumId: null,
      stratumTitle: null,

      producingObjectId: null,
      producingObjectTitle: null,

      mineId: null,
      mineTitle: null,

      typeId: null,
      typeTitle: null,

      licenseZoneId: null,
      licenseZoneTitle: null,

      topX: null,
      topY: null,
      botX: null,
      botY: null,

      md: null,
      oilRate: null,
      liquidRate: null,
      waterCut: null,
      recoverableResources: null,
    };
  }

  static wellToRawOptional({ data, licenseRegionId, producingObject }: Well): WellRawOptional {
    return {
      well_id: data.id,
      title: data.title,
      date: data.date.isValid() ? data.date.format("YYYY-MM-DD") : null,
      isRankingResultDate: data.isRankingResultDate,

      stratumId: data.stratumId,
      stratumTitle: null,

      producingObjectId: producingObject?.id ?? null,
      producingObjectTitle: null,

      mineId: data.mineId,
      mineTitle: null,

      typeId: data.wellTypeId,
      typeTitle: null,

      licenseZoneId: licenseRegionId ?? null,
      licenseZoneTitle: null,

      topX: data.topX,
      topY: data.topY,
      botX: data.botX,
      botY: data.botY,

      md: data.md,
      oilRate: data.oilRate,
      liquidRate: data.liquidRate,
      waterCut: data.waterCut,
      recoverableResources: data.recoverableResources,
    };
  }

  static parsedToRawOptional(data: WellParsedRaw): WellRawOptional {
    return {
      well_id: data.wellId,
      title: data.wellTitle,
      date: data.date,
      isRankingResultDate: false,
      stratumId: data.stratumId,
      stratumTitle: data.stratumTitle,

      producingObjectId: data.producingObjectIdAfter,
      producingObjectTitle: data.producingObjectTitleAfter,

      mineId: data.mineId,
      mineTitle: data.mineCode,

      typeId: data.wellTypeId,
      typeTitle: data.wellTypeTitle,

      licenseZoneId: data.licenseZoneId,
      licenseZoneTitle: data.licenseZoneTitle,

      topX: data.topX,
      topY: data.topY,
      botX: data.botX,
      botY: data.botY,

      md: data.md,
      oilRate: data.oilRate,
      liquidRate: data.liquidRate,
      waterCut: data.waterCut,
      recoverableResources: data.recoverableResources,
    };
  }
}

export { WellNode, type WellRawOptional };
