import { useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { debugColumnsForSimpleTable } from "elements/debugColumn/debugColumn";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { FilterDetails } from "features/techPrediction/filters/types";
import { global } from "models/global";
import { useProjectContext } from "models/project/context/projectContext";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { InjectWell } from "models/project/fact/well/tech/inject";
import { MiningWell } from "models/project/fact/well/tech/mining";
import { DRow, TechWells } from "models/project/fact/well/tech/tech";
import { Range } from "utils/range";

import cn from "./wellsTech.module.less";

export type InputBooleanProps = {
  labels?: [React.ReactNode, React.ReactNode];
  value: boolean | null;
  setValue: (value: boolean) => void;
};

const TECH_PARAMS_FILTER_MAP = {
  name: {
    type: "stringer",
    predicateFactory: (condition, value) => (wellNode) => {
      value = value.toLowerCase();
      const contains = wellNode.well.title.toLowerCase().includes(value);
      return condition === "contains" ? contains : !contains;
    },
  } as FilterDetails<"stringer", DRow, MiningWell | InjectWell, string>,
};

const WellsTech = observer(() => {
  const fact = useFact()!;
  const forecast = useForecast();

  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;

  const store = useMemo(() => new TechWells(fact, forecast, tree), [fact, forecast, tree]);
  const factRange = new Range(fact.factRange.from, fact.lastDescribedYear + 1);

  const columns: Column[] = useMemo(
    () => [
      ...debugColumnsForSimpleTable([
        {
          dataKey: "id",
          title: "ID",
          width: 40,
          hideFilter: true,
        },
      ]),
      {
        dataKey: "name",
        title: "Параметр",
        isSticky: true,
        width: { min: 380, max: 380, competitiveness: 1 },
        type: "string",
        hideFilter: true,
        onCell: ({ indexPath }) => ({ style: { paddingLeft: indexPath.length * 15 } }),
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        type: "string",
        isSticky: true,
        hideFilter: true,
        width: { min: 130, max: 130, competitiveness: 1 },
      },
      /** Fact? columns */
      ...[...(!!forecast ? (global.isPickingFactYears ? forecast.wholeRange : forecast.range) : factRange)].map(
        (year) =>
          ({
            dataKey: `${year}`,
            key: `${year}`,
            title: `${year}`,
            width: { min: 130, max: 130, competitiveness: 1 },
            type: "set",
            hideFilter: true,
            onCell: ({ value, expand }) => ({
              className: classNames(cn.tableCellAlignRight, {
                [cn.oilDiffCellPositive]: value?.name.startsWith("Процент изменения") && ((value[year] ?? 0) as number) > 0,
                [cn.oilDiffCellNegative]: value?.name.startsWith("Процент изменения") && ((value[year] ?? 0) as number) < 0,
              }),
            }),
          } as Column)
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [global.isPickingFactYears]
  );

  return (
    <SimpleTableContext
      exportFileName="Технологические параметры"
      columns={columns}
      data={store}
      tableSettingsId="wells_tech"
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      }}
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
    >
      <PageFrameTitlePortal />
      <Widget headerClassName={cn.tableHeader} />
    </SimpleTableContext>
  );
});

export { TECH_PARAMS_FILTER_MAP, WellsTech };
