import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, runInAction, when } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { OperatingGTMParam } from "models/project/fact/forecast/operatingGTM/operatingGTM";
import { Range } from "utils/range";

import { CalcModel } from "./calcModel";

const defaultValues = [
  {
    param: "optimization",
    rowTitles: [
      {
        param: "Среднее количество дней работы скважины",
        values: [320, 346, 325],
      },
      {
        param: "Количество ГТМ",
        values: [72, 80, 85],
      },
      {
        param: "Средний прирост дебита на одну скважину",
        values: [2.01, 1.88, 2.67],
      },
    ],
  },
  {
    param: "rir",
    rowTitles: [
      {
        param: "Среднее количество дней работы скважины",
        values: [216, 246, 365],
      },
      {
        param: "Количество ГТМ",
        values: [2, 2, 8],
      },
      {
        param: "Средний прирост дебита на одну скважину",
        values: [1.05, 0.95, 0.74],
      },
    ],
  },
  {
    param: "grp",
    rowTitles: [
      {
        param: "Среднее количество дней работы скважины",
        values: [284, 300, 315],
      },
      {
        param: "Количество ГТМ",
        values: [47, 58, 97],
      },
      {
        param: "Средний прирост дебита на одну скважину",
        values: [2.96, 6.08, 4.73],
      },
    ],
  },
  {
    param: "perforation",
    rowTitles: [
      {
        param: "Среднее количество дней работы скважины",
        values: [321, 342, 336],
      },
      {
        param: "Количество ГТМ",
        values: [103, 122, 190],
      },
      {
        param: "Средний прирост дебита на одну скважину",
        values: [2.34, 2.65, 1.81],
      },
    ],
  },
  {
    param: "otherOPZ",
    rowTitles: [
      {
        param: "Среднее количество дней работы скважины",
        values: [323, 345, 342],
      },
      {
        param: "Количество ГТМ",
        values: [62, 77, 129],
      },
      {
        param: "Средний прирост дебита на одну скважину",
        values: [1.88, 2.16, 1.82],
      },
    ],
  },
  {
    param: "repair",
    rowTitles: [
      {
        param: "Количество ГТМ",
        values: [63, 12, 52],
      },
    ],
  },
];

const defaultOtherGTM = [
  {
    parentParam: "Интегральные показатели",
    params: [
      {
        param: "Среднее количество дней работы скважины",
        values: [259, 210, 326],
      },
      {
        param: "Средний прирост дебита на одну скважину",
        values: [0, 0, 0],
      },
    ],
  },
  {
    parentParam: "Спуск ГНО",
    params: [
      {
        param: "Количество ГТМ",
        values: [11, 11, 8],
      },
      {
        param: "Доп. добыча за год проведения ГТМ",
        values: [1.86, 1.51, 1.24],
      },
    ],
  },
  {
    parentParam: "Перевод скважин на мехдобычу",
    params: [
      {
        param: "Количество ГТМ",
        values: [1, 1, 3],
      },
      {
        param: "Доп. добыча за год проведения ГТМ",
        values: [1, 1, 0.9],
      },
    ],
  },
  {
    parentParam: "Пароциклические обработки",
    params: [
      {
        param: "Количество ГТМ",
        values: [0, 0, 0],
      },
      {
        param: "Доп. добыча за год проведения ГТМ",
        values: [0, 0, 0],
      },
    ],
  },
  {
    parentParam: "ОРЭ",
    params: [
      {
        param: "Количество ГТМ",
        values: [0, 0, 0],
      },
      {
        param: "Доп. добыча за год проведения ГТМ",
        values: [0, 0, 0],
      },
    ],
  },
  {
    parentParam: "Ликвидация аварий",
    params: [
      {
        param: "Количество ГТМ",
        values: [3, 4, 5],
      },
      {
        param: "Доп. добыча за год проведения ГТМ",
        values: [0.3, 0.29, 0.76],
      },
    ],
  },
  {
    parentParam: "Прочие работы по КРС",
    params: [
      {
        param: "Количество ГТМ",
        values: [19, 20, 36],
      },
      {
        param: "Доп. добыча за год проведения ГТМ",
        values: [0.52, 0.52, 1.99],
      },
    ],
  },
  {
    parentParam: "Сезонно работающие скважины",
    params: [
      {
        param: "Количество ГТМ",
        values: [0, 0, 0],
      },
      {
        param: "Доп. добыча за год проведения ГТМ",
        values: [0, 0, 0],
      },
    ],
  },
];

type DRow = {
  title: string;
  dataKey: string;
  parentDataKey: string;
  measure?: string | null;
  childrenParams?: Map<string, OperatingGTMParam> | null;
  accuracy: number | null;
  [yearIdx: number]: number | null;
};
class CalcEffectTableModel extends TableNode<DRow, CalcEffectTableNode> {
  calcModel: CalcModel | null = null;
  range: Range;
  constructor(private forecast: Forecast) {
    super();
    makeObservable<CalcEffectTableModel, "init">(this, {
      range: observable,
      submit: action,
      reset: action,
      clear: action,
      init: action,
      defaultFill: action,
      operatingParams: computed,
    });
    this.range = new Range(forecast.range.from + 1, forecast.range.to);
    when(
      () => Array.from(this.operatingParams).length > 0,
      () => this.init()
    );
  }

  private init() {
    runInAction(() => {
      const children = Array.from(this.operatingParams).map(
        ([parentKey, parentParam]) => new CalcEffectTableNode(this, parentParam)
      );
      this.calcModel = new CalcModel(children, this.range, this.forecast);
      this.childrenStore = new ChildrenStoreArray(this, children);
    });
  }

  public defaultFill = () => {
    if (this.childrenStore?.children !== undefined) {
      defaultValues.forEach((item) => {
        item.rowTitles.forEach((row) => {
          const tableRow = Array.from(
            Array.from(this.childrenStore!.children!).find((child) => child.dataKey === item.param)?.children ?? []
          ).find((child) => child.rowInfo.title === row.param);
          if (tableRow !== undefined) {
            row.values.forEach((value, index) => {
              tableRow.mutationsManager?.updateWrapper(this.range.from + index, value);
            });
          }
        });
      });

      defaultOtherGTM.forEach((item) => {
        item.params.forEach((childItem) => {
          const tableRow = Array.from(
            Array.from(
              Array.from(this.childrenStore!.children!).find((child) => child.dataKey === "otherGTM")?.children ?? []
            ).find((child) => child.rowInfo.title === item.parentParam)?.children ?? []
          ).find((row) => row.rowInfo.title === childItem.param);
          if (tableRow !== undefined) {
            childItem.values.forEach((value, index) => {
              tableRow.mutationsManager?.updateWrapper(this.range.from + index, value);
            });
          }
        });
      });
    }
  };

  public submit = async () => {
    if (this.childrenStore) {
      for (let child of this.childrenStore.children) {
        child.mutatedChildren?.forEach((value) => {
          const mutatedKeys = [...Object.keys(value.data), "accuracy"];
          mutatedKeys.forEach((key) => {
            if (key === "accuracy") {
              this.forecast.operatingGTM.setData(
                child.dataKey,
                value.parent.dataKey,
                value.dataKey,
                key,
                value.accuracy
              );
            }
            if (Number.isFinite(+key)) {
              this.forecast.operatingGTM.setData(
                child.dataKey,
                value.parent.dataKey,
                value.dataKey,
                +key,
                value.data[+key]
              );
            }
          });
        });
        if (child.childrenStore) {
          for (let subChild of child.childrenStore.children) {
            subChild.mutatedChildren?.forEach((value) => {
              const mutatedKeys = [...Object.keys(value.data), "accuracy"];
              mutatedKeys.forEach((key) => {
                if (key === "accuracy") {
                  this.forecast.operatingGTM.setData(
                    child.dataKey,
                    value.parent.dataKey,
                    value.dataKey,
                    key,
                    value.accuracy
                  );
                }
                if (Number.isFinite(+key)) {
                  this.forecast.operatingGTM.setData(
                    child.dataKey,
                    value.parent.dataKey,
                    value.dataKey,
                    +key,
                    value.data[+key]
                  );
                }
              });
            });
          }
        }
      }
    }
    this.reset();
    await this.forecast.operatingGTM.save();
    return;
  };

  public reset() {
    runInAction(() => {
      this.mutationsManager?.dropMutations();
    });
  }

  public async clear() {
    await this.forecast.operatingGTM.clear();

    runInAction(() => {
      const children = Array.from(this.operatingParams).map(
        ([parentKey, parentParam]) => new CalcEffectTableNode(this, parentParam)
      );
      this.calcModel = new CalcModel(children, this.range, this.forecast);

      this.childrenStore = new ChildrenStoreArray(this, children);
    });
  }

  get operatingParams() {
    return this.forecast.operatingGTM.params;
  }
}

class CalcEffectTableNode extends TableNode<DRow, TableRow> {
  public dataKey: string;
  public asDRow = (): DRow => ({
    title: this.parentParam.title,
    parentDataKey: this.parentParam.dataKey,
    dataKey: this.dataKey,
    accuracy: null,
  });
  constructor(public readonly parent: CalcEffectTableModel, public readonly parentParam: OperatingGTMParam) {
    super(parent, { isExpandedChildren: true });
    this.dataKey = parentParam.dataKey;
    const children =
      parentParam.children !== null
        ? Array.from(parentParam.children).map(([dataKey, child]) =>
            child.data
              ? new TableRow(this, {
                  title: child.title,
                  dataKey: child.dataKey,
                  measure: child.measure,
                  parentDataKey: this.parentParam.dataKey,
                  accuracy: child.accuracy,

                  ...Array.from(child.data).reduce((acc, [year, value], index) => ({ ...acc, [year]: value }), {}),
                })
              : new TableRow(this, {
                  title: child.title,
                  dataKey: child.dataKey,
                  measure: child.measure,
                  parentDataKey: this.parentParam.dataKey,
                  accuracy: child.accuracy,
                  childrenParams: child.children,
                })
          )
        : null;
    runInAction(() => {
      this.childrenStore = children !== null ? new ChildrenStoreArray(this, children) : null;
    });
  }
}

class TableRow extends TableNode<DRow, TableRow> {
  public data: Omit<DRow, "title" | "measure" | "dataKey" | "children" | "parentDataKey" | "accuracy">;
  public accuracy: number | null;
  public dataKey: string;
  public lastUpdatedKey: number | null = null;
  public asDRow = (): DRow => ({
    title: this.rowInfo.title,
    measure: this.rowInfo.measure,
    dataKey: this.dataKey,
    parentDataKey: this.parent.dataKey,
    accuracy: this.accuracy,
    ...this.data,
  });
  constructor(public readonly parent: CalcEffectTableNode | TableRow, public rowInfo: DRow) {
    super(parent, { isExpandedChildren: true });
    makeObservable(this, {
      data: observable,
      accuracy: observable,
      updateValue: action,
    });
    this.accuracy = rowInfo.accuracy;
    this.dataKey = rowInfo.dataKey;
    const children = rowInfo.childrenParams
      ? Array.from(rowInfo.childrenParams!.values()).map(
          (param) =>
            new TableRow(this, {
              title: param.title,
              dataKey: param.dataKey,
              measure: param.measure,
              parentDataKey: this.parent.dataKey,
              accuracy: param.accuracy,
              ...Array.from(param.data!).reduce((acc, [year, value], index) => ({ ...acc, [year]: value }), {}),
            })
        )
      : null;
    this.data = Object.fromEntries(
      Object.keys(rowInfo)
        .filter((key) => key !== "name" && key !== "measure")
        .map((key) => [+key, rowInfo[+key]])
    );
    runInAction(() => {
      this.childrenStore = children !== null ? new ChildrenStoreArray(this, children) : null;
    });
  }

  public updateValue(k: any, newValue: any): [prevValue: any, currValue: any] {
    const key = k as keyof DRow;
    if (
      key === "title" ||
      key === "measure" ||
      key === "dataKey" ||
      key === "childrenParams" ||
      key === "parentDataKey"
    ) {
      console.error("attempt to update not editable field");
      return [undefined, undefined];
    }
    if (key === "accuracy") {
      const prev = this.accuracy;
      this.accuracy = newValue;
      return [prev, this.accuracy];
    }
    const prev = this.data[key];
    runInAction(() => {
      this.data[key] = newValue;
    });
    this.lastUpdatedKey = key;

    return [prev, this.data[key]];
  }
}
export { CalcEffectTableModel, CalcEffectTableNode, TableRow };

export type { DRow };
