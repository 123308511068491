import { FC } from "react";
import FullScreen from "react-fullscreen-crossbrowser";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { ChartContext } from "@okopok/axes_context";
import { Lines } from "@okopok/axes_context";
import { Button, Typography } from "antd";
import { observer } from "mobx-react";

import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import StackedBars from "elements/charts/stackedBars/stackedBars";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext, useTooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { useLinesBarsComposition } from "features/plot/useLinesBarsComposition";
import { SummaryModel } from "features/techPrediction/summary/summaryModel";
import { useBooleanState } from "utils/useBooleanState";

import cn from "./techSummaryChart.module.less";

type DataSeries = {
  y: (number | null)[];
  title?: string;
  channel: string;
};

type TechSummaryAxisData = {
  dataSet: DataSeries[];
  unit: string;
  side?: "left" | "right";
};

type TechSummaryForecastProps = {
  title: string;

  summaryModel: SummaryModel;
};

const TechSummaryChart: FC<TechSummaryForecastProps> = observer(({ title, summaryModel }) => {
  const tooltipManager = useTooltipDataManager(
    (summaryModel.lines ?? []).filter((item) => !summaryModel.hidden.has(item.channel)).map((item) => item.line),
    summaryModel.bars
  );
  const [isFullscreen, openFullscreen, closeFullscreen, switchFullscreen] = useBooleanState();
  return (
    <div className={cn.layout} data-testid="chart">
      <FullScreen enabled={isFullscreen} onClose={closeFullscreen} onOpen={openFullscreen}>
        <div className={cn.titleContainer}>
          <div className={cn.header}>
            <Typography.Title level={4} className={cn.title}>
              {title}
            </Typography.Title>
          </div>
          <Button onClick={switchFullscreen} icon={isFullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />} />
        </div>
        <div className={cn.wrap}>
          <ChartContext
            axes={useLinesBarsComposition(
              summaryModel.lines?.filter((item) => !summaryModel.hidden.has(item.channel)).map((item) => item.line),
              summaryModel.bars,
              summaryModel.paddings,
              summaryModel.axisTitles,
              summaryModel.rightAxes,
              undefined
            )}
            className={cn.chart}
          >
            <TooltipContext.Provider value={tooltipManager}>
              {summaryModel.bars && <StackedBars bars={summaryModel.bars} />}
              <Lines
                data={(summaryModel.lines ?? [])
                  .filter((item) => !summaryModel.hidden.has(item.channel))
                  .map((item) => item.line)
                  .filter((line) => line.y.find((value) => value !== undefined))}
              />
              <PointerLine />
            </TooltipContext.Provider>
          </ChartContext>
          <TooltipDiv manager={tooltipManager} />
        </div>
      </FullScreen>
    </div>
  );
});

export type { TechSummaryAxisData };
export { TechSummaryChart };
