import { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMapContext, useMapRefsContext } from "@okopok/axes_context";
import classNames from "classnames";
import * as d3 from "d3";
import { D3DragEvent } from "d3";
import { observer } from "mobx-react";

import { NodeType, useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { Label } from "../label";
import { NodeIcon } from "../nodeIcon";
import { formatScale, getSelectedText, getZoomParameters, tooltipText } from "../utils";

import { Icon } from "./icon";

import cn from "./nodes.module.less";

type NodeProps = {
  onClick: (id: string) => void;
  isActive: boolean;
  node: NodeType;
};

const Node: FC<NodeProps> = observer(({ node, isActive, onClick }) => {
  const [isDrag, setIsDrag] = useState(false);

  const nodeRef = useRef(null);

  const { scale, zoom } = useMapContext();
  const { zoomedScale } = useMapRefsContext();
  const manager = useInfrastructureMapContext();
  const { isSelection, isCreate, isDrawing, isRuler, selection } = manager;

  const { zoomScale } = getZoomParameters(zoom);

  const lineEndUuid = useMemo(() => (manager.lineEnd as NodeType)?.uuid, [manager.lineEnd]);

  const onPositionChanged = useCallback(
    (event: D3DragEvent<SVGElement, any, any>, id: string) => {
      const { x, y } = formatScale(event, zoomedScale);
      manager.updateNodePosition({ x, y, uuid: id });
      manager.updateCursorPosition({ x, y });
    },
    [manager, zoomedScale]
  );

  useEffect(() => {
    const dragHandler = d3
      .drag()
      .on("drag", (drag) => {
        if (manager.isCreate && !node.isFactual) {
          onPositionChanged?.(drag, node.uuid);
          setIsDrag(true);
          manager.setTooltip();
        }
      })
      .on("end", () => {
        manager.updateDragging(false);
        setIsDrag(false);
      });
    d3.select(nodeRef.current).call(dragHandler as any);
  }, [onPositionChanged, manager, node]);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    onClick?.(node.uuid);

    if (manager.isSelection) {
      manager.selection.setSelectedNodeIds(node.uuid, !!(event.ctrlKey || event.metaKey || event.shiftKey));
    }
    if (manager.isCreate) {
      manager.pushPipe(node);
    }
  };

  useEffect(() => {
    if (isDrawing && lineEndUuid && manager.newPipe?.start!.uuid !== lineEndUuid) {
      manager.setTooltip(tooltipText.objectToConnectWithPipeline);
    }
  }, [manager, isDrawing, lineEndUuid, manager.newPipe]);

  useEffect(() => {
    if (isSelection) {
      if (!node.isSelected) {
        manager.setTooltip(tooltipText.clickToSelect);
      } else {
        manager.setTooltip(getSelectedText(selection.selectedNodeIds.length, selection.selectedPipeIds.length));
      }
      return;
    }
  }, [manager, isSelection, selection, node.isSelected]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (isDrag || isRuler) {
        manager.setTooltip("");
        return;
      }

      if (isCreate) {
        if (isDrawing && lineEndUuid) {
          manager.setTooltip(tooltipText.objectToConnectWithPipeline);
        } else {
          manager.setTooltip(tooltipText.objectToDrawPipelineFromIt);
        }
        return;
      }

      if (isSelection) {
        if (!node.isSelected) {
          manager.setTooltip(tooltipText.clickToSelect);
        } else {
          manager.setTooltip(getSelectedText(selection.selectedNodeIds.length, selection.selectedPipeIds.length));
        }
        return;
      }
    },
    [isCreate, isDrag, isDrawing, isRuler, lineEndUuid, manager, selection, isSelection, node.isSelected]
  );

  const handleMouseLeave = () => {
    manager.setTooltip("");
  };

  return (
    <g
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      className={classNames((manager.isDrawing || node.isDisabled || node.isDisabledDate) && cn.disabled)}
      transform={`translate(${scale.x(node.x)}, ${scale.y(node.y)}) scale(${zoomScale})`}
    >
      <NodeIcon displacement={28 / 2}>
        {manager.showMapNodeText && (
          <Label y={-15} x={-12}>
            {node.title}
          </Label>
        )}
        <g ref={nodeRef} className={cn.icon}>
          <Icon
            isSelected={isActive}
            isDisabled={node.isDisabled}
            isDisabledDate={!!node.isDisabledDate}
            type={node.type}
            pipeMode={manager.pipeMode}
          />
        </g>
      </NodeIcon>
    </g>
  );
});

export { Node };
