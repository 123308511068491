import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, override, transaction, when } from "mobx";

import { Fact } from "models/project/fact/fact";
import { Forecast } from "models/project/fact/forecast/forecast";
import { ProducingObjectsParams } from "models/project/fact/producingObjectsParams/producingObjectsParams";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { Range } from "utils/range";

type DRow = {
  year: Dayjs | null;
  [producingObjectId: number]: number | null; // typeId
};

class HTRTypesRow extends TableNode<DRow> {
  asDRow = (): DRow => ({
    year: HTRTypesRow.yearToDayJS(this.year),
    ...this.data,
  });

  constructor(private parent: HTRTypesModel, private year: number) {
    super(parent, { mutable: true });
    this.childrenStore = null;

    makeObservable<HTRTypesRow, "data">(this, {
      data: computed,
      updateValue: action,
    });
  }

  private get data(): { [producingObjectId: number]: number | null } {
    if (!this.parent.data) {
      return {};
    }
    const range = this.parent.params.paramsRange;
    return this.parent.producingObjects!.reduce((datum, curr) => {
      datum[curr.id] = this.parent.data![curr.id][range.id(this.year)];
      return datum;
    }, {} as Record<number, number | null>);
  }

  public static yearToDayJS(year: number): Dayjs {
    return dayjs().year(year).startOf("year");
  }

  updateValue(k: any, newValue: number | null): [prev: any, curr: any] {
    const key = k as keyof DRow;
    if (key === "year") {
      console.error("attempt to update not editable field");
      return [undefined, undefined];
    }

    const prev = this.data![key];
    this.data[key] = newValue;

    this.parent.updateTypeId(key, this.year, newValue);

    return [prev, this.data[key]];
  }
}

class HTRTypesModel extends TableNode<DRow, HTRTypesRow> {
  public data?: { [producingObjectId: number]: (number | null)[] };

  public producingObjects?: ProducingObject[];

  private displayRange: Range;

  constructor(private fact: Fact, private forecast: Forecast | null) {
    super(null, { mutable: true });

    if (forecast) {
      this.displayRange = forecast.range;
    } else {
      this.displayRange = new Range(2013, fact.factRange.to);
    }

    makeObservable<HTRTypesModel, "init" | "initStore">(this, {
      data: observable,
      isUpdated: override,
      init: action,
      initStore: action,
      updateTypeId: action,
    });

    when(
      () => this.fact.producingObjects.isLoading === false && this.params.isLoading === false,
      () => this.init()
    );
  }

  public get isUpdated(): boolean {
    if (!this.data) {
      return false;
    }
    return JSON.stringify(this.data) !== JSON.stringify(this.params.htrTypesIds);
  }

  private init() {
    this.producingObjects = [...this.fact.producingObjects.values!];

    this.initStore();

    this.childrenStore = new ChildrenStoreArray(
      this,
      [...this.displayRange].map((year) => new HTRTypesRow(this, year))
    );
  }

  private initStore() {
    if (!this.producingObjects || this.params.isLoading) {
      return;
    }
    const htrTypes = this.params.htrTypesIds;
    const prodObjIds = this.producingObjects!.map((p) => p.id);

    const data: { [producingObjectId: number]: (number | null)[] } = {};
    for (const poId of prodObjIds) {
      data[poId] = htrTypes?.[poId]?.slice() ?? this.params.paramsRange.array.fill(null);
    }
    this.data = data;
  }

  public get params(): ProducingObjectsParams {
    return (this.forecast ?? this.fact).producingObjectsParams;
  }

  public updateTypeId = (producingObjectId: number, yearFrom: number, newTypeId: number | null) => {
    if (!this.data) {
      return;
    }
    transaction(() => {
      const startIdx = this.params.paramsRange.id(yearFrom);
      for (let i = startIdx; i < this.data![producingObjectId].length; ++i) {
        this.data![producingObjectId][i] = newTypeId;
      }
    });
  };

  public submit = async () => {
    if (!this.data) {
      return;
    }
    await this.params.updateHTR(this.data);
    this.mutationsManager?.dropMutations();
    this.init();
  };
}

export { type DRow, HTRTypesModel };
