import { FC } from "react";

import { Legend } from "features/techForecast/wellTechForecast/results/chart/legend/legend";
import { TechSummaryChart } from "features/techSummaryChart/techSummaryChart";

import { SummaryModel } from "./summaryModel";

import cn from "./summary.module.less";

type ChartProps = {
  summaryModel: SummaryModel;
};

const Chart: FC<ChartProps> = ({ summaryModel }) => {
  return (
    <div className={cn.chart}>
      <TechSummaryChart summaryModel={summaryModel} title="График" />
      <Legend chartModel={summaryModel} />
    </div>
  );
};

export { Chart };
