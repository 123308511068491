import { makeAutoObservable } from "mobx";

import { WellPadRaw } from "services/back/wellPads";

class WellPad {
  public id: number;
  public title: string;
  public fieldId: number;
  public isFactual: boolean;

  constructor(padRaw: WellPadRaw) {
    makeAutoObservable(this);
    this.id = padRaw.id;
    this.title = padRaw.title;
    this.fieldId = padRaw.fieldId;
    this.isFactual = padRaw.isFactual;
  }

  public get isUnsaved(): boolean {
    return this.id < 0;
  }
}

export { WellPad };
