import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs from "dayjs";
import { action, makeObservable, observable } from "mobx";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { NodeType } from "models/project/fact/infrastructure/nodes";
import { Well as WellType } from "models/project/fact/well/well";
import { conditionallyArr } from "utils/conditionally";

type Mines = (NodeType & { wells: WellType[] })[];

type DRow = {
  startedAt: string;
  well?: string;
  title: string;
  altitude?: number;
  cost?: number;
  x?: number;
  y?: number;
};

class Well extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    well: this.data.title,
    startedAt: dayjs(this.data.date).format("MM.YYYY"),
    title: this.parent.data.title,
  });
  public readonly data: WellType;

  constructor(private parent: WellPad, data: WellType) {
    super(parent);

    this.data = data;
    makeObservable(this, {
      data: observable,
    });
  }
}

class WellPad extends TableNode<DRow, Well> {
  public asDRow = (): DRow => ({
    ...this.data,
    startedAt: dayjs(this.data.startedAt).format("MM.YYYY"),
  });
  constructor(private parent: WellPads, public data: NodeType, private wells: WellType[]) {
    super(parent, { isExpandedChildren: false });

    makeObservable(this, {
      initChildren: action,
      updateValue: action,
    });
    this.initChildren();
  }

  initChildren = () => {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.wells.map((el) => new Well(this, el))
    );
  };

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    const [k, value] = [key as keyof NodeType, newValue as never];
    if (k === "altitude" || k === "x" || k === "y") {
      value && this.parent.infrastructure.nodes.update({ ...this.data, [k]: value });
      const prev = this.data[k];
      this.data[k] = value;
      return [prev, value];
    }
    return [undefined, undefined];
  }
}

class WellPads extends TableNode<DRow, WellPad> {
  public asDRow = () =>
    ({
      title: this.name ?? "Без характеристик",
    } as DRow);
  constructor(parent: WellPadsModel, public mines: Mines, public name: string, public infrastructure: Infrastructure) {
    super(parent, { isExpandedChildren: true });

    this.initChildren();
  }

  private initChildren = () => {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.mines.map((el) => new WellPad(this, el, el.wells))
    );
  };
}

class WellPadsModel extends TableNode<DRow, WellPads> {
  public oldMines?: WellPads;
  public newMines?: WellPads;

  constructor(public infrastructure: Infrastructure) {
    super();

    makeObservable<this, "initChildren">(this, {
      newMines: observable,
      oldMines: observable,
      initChildren: action,
    });

    this.initChildren();
  }

  private initChildren = () => {
    const wells = this.infrastructure.wells || [];
    const nodes = this.infrastructure.nodes.mines || [];

    const wellsMap = new Map<number, WellType[]>();
    for (const well of wells) {
      if (wellsMap.get(well.mineId)) {
        wellsMap.set(well.mineId, [...wellsMap.get(well.mineId)!, well]);
      } else {
        wellsMap.set(well.mineId, [well]);
      }
    }

    const mines: Mines = nodes.map((el) => ({ ...el, wells: el.mineId ? wellsMap.get(el.mineId) || [] : [] }));

    const { oldMines, newMines } = mines.reduce(
      (acc, el) => {
        if (el.isFactual) {
          acc.oldMines.push(el);
        } else {
          acc.newMines.push(el);
        }
        return acc;
      },
      { oldMines: [], newMines: [] } as { oldMines: Mines; newMines: Mines }
    );

    this.childrenStore = new ChildrenStoreArray(this, [
      ...conditionallyArr(!!newMines.length, new WellPads(this, newMines, "Новые кусты", this.infrastructure)),
      ...conditionallyArr(!!oldMines.length, new WellPads(this, oldMines, "Базовые кусты", this.infrastructure)),
    ]);
  };
}

export { type DRow as DRowMines, WellPads, WellPadsModel };
