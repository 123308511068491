import { useMemo } from "react";

import { Column } from "features/tableDebug/simpleTable";

import { useInfrastructure } from "../useInfrastructure";

import { Pipes } from "./facilitiesManager/pipes";

import cn from "./infrastructureFacilities.module.less";

const useColumns = (mode: "prod" | "inj"): Column[] => {
  const width = useMemo(() => (mode === "prod" ? { min: 500, max: 520, competitiveness: 1 } : { min: 700, max: 720, competitiveness: 1 }), [mode]);
  return useMemo(
    () => [
      {
        title: "Назначение трубопровода",
        width: width,
        isSticky: true,
        dataKey: "title",
        type: "string",
        editable: true,
        hideFilter: true,
        onCell: () => ({ className: cn.tableCellInput }),
      },
      {
        title: "Материальное исполнение",
        width: { min: 120, max: 300, competitiveness: 1 },
        isSticky: true,
        dataKey: "material",
        type: "select",
        options: [
          { value: "Сталь", label: "Сталь" },
          { value: "ГПАТ", label: "ГПАТ" },
        ],
        editable: true,
        onCell: () => ({ className: cn.tableCellAlignLeft }),
      },
      {
        title: "Наличие внтур. антикор. покрытия",
        width: { min: 200, max: 300, competitiveness: 1 },
        isSticky: true,
        hideFilter: true,
        dataKey: "isAnticorCover",
        type: "set",
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Внешний диаметр, мм",
        width: { min: 120, max: 120, competitiveness: 1 },
        dataKey: "diameterOuter",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Толщина стенки, мм",
        width: { min: 100, max: 120, competitiveness: 1 },
        dataKey: "thickness",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Шерохов-ть стенки, мм",
        width: { min: 110, max: 120, competitiveness: 1 },
        dataKey: "roughness",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Пред.скорость потока, м/с",
        width: { min: 120, max: 150, competitiveness: 1 },
        dataKey: "limitingVelocity",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Рабочее давление, МПа",
        width: { min: 130, max: 150, competitiveness: 1 },
        dataKey: "workPressure",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Пред. градиент давления, МПа/км",
        width: { min: 150, max: 150, competitiveness: 1 },
        dataKey: "limitingPressureGradient",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Итого (Строительство), тыс руб / км",
        width: { min: 200, max: 220, competitiveness: 1 },
        dataKey: "total",
        hideFilter: true,
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "ПИР (Строительство), тыс руб / км",
        width: { min: 200, max: 220, competitiveness: 1 },
        dataKey: "dsw",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Оборудование (Строительство), тыс руб / км",
        width: { min: 260, max: 300, competitiveness: 1 },
        dataKey: "equipment",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "СМР (Строительство), тыс руб / км",
        width: { min: 200, max: 220, competitiveness: 1 },
        dataKey: "ciw",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Прочее (Строительство), тыс руб / км",
        width: { min: 200, max: 220, competitiveness: 1 },
        dataKey: "other",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Итого (Реконструкция), тыс руб / км",
        width: { min: 200, max: 220, competitiveness: 1 },
        dataKey: "totalReconstruction",
        hideFilter: true,
        type: "number",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "ПИР (Реконструкция), тыс руб / км",
        width: { min: 200, max: 220, competitiveness: 1 },
        dataKey: "reconstructionDsw",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Оборудование (Реконструкция), тыс руб / км",
        width: { min: 260, max: 300, competitiveness: 1 },
        dataKey: "reconstructionEquipment",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "СМР (Реконструкция), тыс руб / км",
        width: { min: 200, max: 220, competitiveness: 1 },
        dataKey: "reconstructionCiw",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Прочее (Реконструкция), тыс руб / км",
        width: { min: 200, max: 220, competitiveness: 1 },
        dataKey: "reconstructionOther",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Период реконструкции, года",
        width: { min: 180, max: 250, competitiveness: 1 },
        dataKey: "period",
        type: "number",
        hideFilter: true,
        editable: true,
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
    ],
    [width]
  );
};

const useFacilitiesPipesTableModel = (mode: "prod" | "inj") => {
  const infrastructure = useInfrastructure();
  const columns = useColumns(mode);
  const store = useMemo(() => new Pipes(infrastructure, mode), [infrastructure, mode]);
  return { columns, store };
};

export { useFacilitiesPipesTableModel };
