import { useMemo } from "react";
import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs from "dayjs";
import { computed, makeObservable, observable, runInAction } from "mobx";

import { Forecast, useForecast } from "models/project/fact/forecast/forecast";
import { WellPad } from "models/project/fact/wellPad/wellPad";
import { getRandomUid } from "utils/random";

import { NodeType } from "../infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";

type DRow = {
  title: string | null;
  x: number | null;
  y: number | null;
};

class Mine extends TableNode<DRow> {
  public title: string;
  public mineId: number;
  public x: number | null = null;
  public y: number | null = null;

  constructor(private parent: Mines, mine: WellPad) {
    super(parent);

    makeObservable(this, {
      title: observable,
      mineId: observable,
      x: observable,
      y: observable,
    });

    this.title = mine.title;
    this.mineId = mine.id;
    this.x = null;
    this.y = null;
  }

  public asDRow = (): DRow => ({
    title: this.title,
    x: this.x,
    y: this.y,
  });

  static createMine = (mine: { title: string; mineId: number; x: number; y: number }): NodeType => ({
    ...mine,
    uuid: getRandomUid(),
    startedAt: dayjs(),
    pressure: 0,
    type: "mine",
    isFactual: false,
    altitude: 266,
    isDisabled: false,
  });

  updateValue(key: "title" | "x" | "y", newValue: number | null): [prevValue: any, currValue: any] {
    if (key === "title") {
      return [undefined, undefined];
    }

    if (key === "x") {
      if (newValue !== null && this.y !== null) {
        this.asTableItem.select?.onSelect();
      }
      if (newValue === null || this.y === null) {
        this.asTableItem.select?.onDeselect();
      }
    }
    if (key === "y") {
      if (newValue !== null && this.x !== null) {
        this.asTableItem.select?.onSelect();
      }
      if (newValue === null || this.x === null) {
        this.asTableItem.select?.onDeselect();
      }
    }

    const prev = this[key];
    this[key] = newValue;
    return [prev, newValue];
  }
}

class Mines extends TableNode<DRow, Mine> {
  constructor(private mines: WellPad[], private forecast: Forecast) {
    super();

    makeObservable(this, {
      selectedMines: computed,
      isComplete: computed,
      selectedMinesLength: computed,
      unsavedMines: computed,
    });

    this.init();
  }

  public init = () => {
    const wellMines = new Set();
    this.forecast.wells.wells.forEach(({ mineId }) => wellMines.add(mineId));
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        this.mines.filter(({ id }) => wellMines.has(id)).map((mine) => new Mine(this, mine))
      );
    });
  };

  public get selectedMines(): Mine[] {
    if (this.selectManager?.isRootSelected) {
      return [...(this.childrenStore?.children as Mine[])];
    }
    return [...(this.selectManager?.selectedChildren.values() ?? [])] as Mine[];
  }

  public get unsavedMines(): NodeType[] {
    return this.selectedMines?.map((el) => Mine.createMine({ ...el, x: el.x ?? 0, y: el.y ?? 0 }));
  }

  public get isComplete() {
    if (this.selectedMines.length === 0) return true;
    return this.selectedMines.every(({ x, y }) => typeof x === "number" && typeof y === "number");
  }

  public get selectedMinesLength() {
    return this.selectedMines.length;
  }
}

const useMines = () => {
  const forecast = useForecast()!;
  const { wellPads, infrastructure } = forecast;
  const { nodes } = infrastructure;
  const mines = wellPads.padsIsNotFactual.filter((el) => !nodes.mines.some((mine) => el.id === mine.mineId));

  return useMemo(() => new Mines(mines, forecast), [mines, forecast]);
};

export { Mines, useMines };
