import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { reaction, runInAction } from "mobx";

import { ParamsRow } from "features/useMetrics/paramsRow";
import { global } from "models/global";
import { ForecastResult } from "models/project/fact/forecast/forecastResult";
import { OptimizerModal } from "models/project/fact/forecast/optimizerModal";
import { FondType } from "models/project/fact/well/well";
import { Well } from "models/project/fact/well/well";
import { TreeRoot } from "models/tree/tree";
import { zip } from "utils/itertools";
import { Range } from "utils/range";

type WellsResultType = {
  id?: number;
  fond?: FondType;
  key: number;
  title: string;
  measure: string | null;
  hideExpand?: boolean;
  optimizerModel?: OptimizerModal;
  summary?: number | null;
  select?: {
    onSelect: () => void;
    checked?: () => boolean | "partially";
    tooltip?: string;
  };
  [year: number]: number | null;
};

const extractOilProduction = (children: ParamsRow[]) =>
  children.find(({ title }) => title === "CF")?.children?.find(({ title }) => title === "Добыча нефти");

class WellsResultStore extends TableNode<WellsResultType, WellsResultNode> {
  private range: Range;

  constructor(
    result: ForecastResult,
    private readonly tree?: TreeRoot<Well>,
    optimizerModel?: OptimizerModal,
    startYear?: number
  ) {
    super(null, { selectable: false, mutable: false });
    this.range = result.forecast.wholeRange;

    reaction(
      () => this.tree?.selectedItems,
      (selected) => this.filterSelected(new Set(selected?.map((node) => node.item!.id))),
      { fireImmediately: true }
    );

    let children = result.wells.tableItems.map((item) => new WellsResultNode(this, item, this.range));

    if (optimizerModel) {
      const wells = result.wells.tableItems.filter(
        ({ title }) =>
          title !== "Суммарные экономические показатели" && title !== "Суммарные технологические показатели"
      );
      const baseWells: ParamsRow[] = [];
      const newWells: ParamsRow[] = [];

      for (const well of wells) {
        const wellInfo = global.wells.at(well.id ?? 0);

        if (wellInfo && wellInfo.isFactual) {
          baseWells.push(well);
        } else {
          newWells.push(well);
        }
      }

      const base: ParamsRow = {
        children: baseWells,
        row: 0,
        title: "Базовый фонд",
        measure: "",
        key: 0,
        level: 0,
      };

      const newFund: ParamsRow = {
        children: newWells,
        row: 0,
        title: "Новый фонд",
        measure: "",
        key: 0,
        level: 0,
      };

      children = [
        ...((newFund.children?.length ?? 0) > 0
          ? [new WellsResultNode(this, newFund, this.range, optimizerModel, "New", startYear)]
          : []),
        ...((base.children?.length ?? 0) > 0
          ? [new WellsResultNode(this, base, this.range, optimizerModel, "Base", startYear)]
          : []),
      ];
    }

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(this, children);
    });
  }

  private filterSelected(selectedWellsIds: Set<number>) {
    const filteredChildren =
      this.childrenStore?.filter((node) => {
        if (
          node.wellInfo.title === "Суммарные экономические показатели" ||
          node.wellInfo.title === "Суммарные технологические показатели"
        ) {
          return true;
        }

        return selectedWellsIds.has(node.wellInfo.id ?? 0);
      }) || [];

    return filteredChildren;
  }
}

class WellsResultNode extends TableNode<WellsResultType, WellsResultNode> {
  public asDRow(): WellsResultType {
    return {
      id: this.wellInfo.id,
      fond: this.fond,
      key: this.wellInfo.key as number,
      title: this.wellInfo.title,
      measure: this.wellInfo.measure,
      hideExpand: this.optimizerModel && this.wellInfo.title === "CF",
      optimizerModel: this.optimizerModel,
      summary: this.summary,
      select: !["NPV", "CF", "Добыча нефти"].includes(this.wellInfo.title)
        ? this.optimizerModel?.select(this.wellInfo)
        : undefined,
      ...this.values,
    };
  }
  constructor(
    parent: WellsResultStore | WellsResultNode,
    public readonly wellInfo: ParamsRow,
    public range: Range,
    private optimizerModel?: OptimizerModal,
    private fond?: FondType,
    private startYear?: number
  ) {
    super(parent, { selectable: false, mutable: false, isExpandedChildren: wellInfo.title !== "CF" });
    runInAction(
      () =>
        (this.childrenStore =
          wellInfo.children && wellInfo.children?.length > 0 && !(wellInfo.title === "CF" && this.optimizerModel)
            ? new ChildrenStoreArray(
                this,
                optimizerModel
                  ? [
                      ...wellInfo.children
                        .filter(
                          ({ title }) =>
                            title === "NPV" ||
                            title === "CF" ||
                            wellInfo.title === "Базовый фонд" ||
                            wellInfo.title === "Новый фонд"
                        )
                        .map(
                          (item: any) =>
                            new WellsResultNode(this, item, this.range, optimizerModel, fond, this.startYear)
                        ),
                      ...(extractOilProduction(wellInfo.children)
                        ? [
                            new WellsResultNode(
                              this,
                              //@ts-ignore
                              extractOilProduction(wellInfo.children),
                              this.range,
                              optimizerModel,
                              fond,
                              this.startYear
                            ),
                          ]
                        : []),
                    ]
                  : wellInfo.children.map((item: any) => new WellsResultNode(this, item, this.range))
              )
            : null)
    );
  }

  get summary() {
    //@ts-ignore
    return this.wellInfo.title === "NPV" ? (Object.values(this.values).findLast((v) => v) as number) : null;
  }

  get values() {
    if (this.startYear) {
      const start = this.startYear ?? 0;
      return Object.fromEntries(
        zip(
          [...this.range].filter((year) => year >= start),
          this.wellInfo.values?.slice(start - this.range.from) ?? []
        )
      );
    }

    return Object.fromEntries(zip([...this.range], this.wellInfo.values ?? []));
  }
}
export { WellsResultStore };
export type { WellsResultType };
