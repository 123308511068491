import { FC, ReactNode } from "react";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { MapManager } from "@okopok/axes_context";
import { Button, Radio, Tooltip } from "antd";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { global } from "models/global";

import { useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";

import { ReactComponent as CreatePipeIcon } from "./icons/createPipeIcon.svg";
import { ReactComponent as FullScreenIcon } from "./icons/fullScreen.svg";
import { ReactComponent as LayersIcon } from "./icons/layers.svg";
import { ReactComponent as OilPipeIcon } from "./icons/oilPipe.svg";
import { ReactComponent as PressureIcon } from "./icons/pressure.svg";
import { ReactComponent as RulerIcon } from "./icons/rulerIcon.svg";
import { ReactComponent as SelectionIcon } from "./icons/selectionIcon.svg";
import { ReactComponent as ShowMapNodeTextIcon } from "./icons/showMapNodeText.svg";
import { ReactComponent as ShowMapPipeTextIcon } from "./icons/showMapPipeText.svg";
import { ReactComponent as ShowTooltipsIcon } from "./icons/showTooltips.svg";
import { ReactComponent as VelocityIcon } from "./icons/velocity.svg";
import { ReactComponent as WaterPipeIcon } from "./icons/waterPipe.svg";

import cn from "./mapToolbar.module.less";

const interactionButtons = [
  {
    value: "selection",
    title: "Режим выделения (клавиша C)",
    icon: <SelectionIcon />,
  },
  {
    value: "create",
    title: "Режим создания топологии (клавиша T)",
    icon: <CreatePipeIcon />,
  },
  {
    value: "ruler",
    title: "Режим измерения расстояния (клавиша L)",
    icon: <RulerIcon />,
  },
];

const typePipeButtons = [
  {
    value: "water",
    title: "Водовод",
    icon: <WaterPipeIcon />,
  },
  {
    value: "oil",
    title: "Нефтепровод",
    icon: <OilPipeIcon />,
  },
];

const modePipeColor = [
  {
    value: "velocity",
    title: "Скорость потока",
    icon: <VelocityIcon />,
  },
  {
    value: "pressure",
    title: "Давление",
    icon: <PressureIcon />,
  },
];

const TooltipCustom: FC<{ title: string; show: boolean; children: ReactNode }> = ({ title, show, children }) => {
  if (!show) return <>{children}</>;
  return <Tooltip title={title}>{children}</Tooltip>;
};

type Props = {
  manager: MapManager | null;
};

const MapToolbar: FC<Props> = observer(({ manager }) => {
  const dataManager = useInfrastructureMapContext();
  return (
    <div className={cn.toolbar}>
      <div className={cn.radioButtonGroups}>
        <Radio.Group
          defaultValue="selection"
          value={dataManager.interactionMode}
          buttonStyle="solid"
          className={cn.radioGroup}
          disabled={dataManager.selection.isGrab}
          onChange={({ target }) => dataManager.updateInteractionMode(target.value)}
          onMouseEnter={() => dataManager.setTooltip()}
        >
          {interactionButtons.map((el, index) => (
            <TooltipCustom key={index} title={el.title} show={dataManager.showMapTooltip}>
              <Radio.Button value={el.value}>
                <Icon width="16px" height="16px" viewBox="0 0 16 16" content={el.icon} />
              </Radio.Button>
            </TooltipCustom>
          ))}
        </Radio.Group>
        <Radio.Group value={dataManager.pipeMode} buttonStyle="solid" onChange={({ target }) => dataManager.setPipeMode(target.value)}>
          {typePipeButtons.map((el, index) => (
            <TooltipCustom key={index} title={el.title} show={dataManager.showMapTooltip}>
              <Radio.Button value={el.value}>
                <Icon width="16px" height="16px" viewBox="0 0 16 16" content={el.icon} />
              </Radio.Button>
            </TooltipCustom>
          ))}
        </Radio.Group>
      </div>
      <div className={cn.actionButtons}>
        <Radio.Group value={dataManager.pipeColorMode} buttonStyle="solid" onChange={({ target }) => dataManager.setPipeColorMode(target.value)}>
          {modePipeColor.map((el, index) => (
            <TooltipCustom key={index} title={el.title} show={dataManager.showMapTooltip}>
              <Radio.Button value={el.value}>
                <Icon width="16px" height="16px" viewBox="0 0 16 16" content={el.icon} />
              </Radio.Button>
            </TooltipCustom>
          ))}
        </Radio.Group>
        <TooltipCustom title="Показать все" show={dataManager.showMapTooltip}>
          <Button onClick={manager?.setInitialScale} icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<FullScreenIcon />} />} />
        </TooltipCustom>
        {global.IS_DEBUG_ZONE && (
          <TooltipCustom title="Скачать пускач" show={dataManager.showMapTooltip}>
            <Button onClick={dataManager.downloadForSolverJSON} icon={<CloudDownloadOutlined />} />
          </TooltipCustom>
        )}
        <TooltipCustom title="Отображать названия узлов" show={dataManager.showMapTooltip}>
          <Button
            onClick={() => dataManager.setShowMapNodeText(!dataManager.showMapNodeText)}
            type={dataManager.showMapNodeText ? "primary" : "default"}
            icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ShowMapNodeTextIcon />} />}
          />
        </TooltipCustom>
        <TooltipCustom title="Отображать названия трубопроводов" show={dataManager.showMapTooltip}>
          <Button
            onClick={() => dataManager.setShowMapPipeText(!dataManager.showMapPipeText)}
            type={dataManager.showMapPipeText ? "primary" : "default"}
            icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ShowMapPipeTextIcon />} />}
          />
        </TooltipCustom>
        <Tooltip title="Отображать подсказки к возможным действиям">
          <Button
            onClick={() => dataManager.setShowMapTooltip(!dataManager.showMapTooltip)}
            type={dataManager.showMapTooltip ? "primary" : "default"}
            icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ShowTooltipsIcon />} />}
          />
        </Tooltip>
        <TooltipCustom title="Слои" show={dataManager.showMapTooltip}>
          <Button disabled icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<LayersIcon />} />} />
        </TooltipCustom>
      </div>
    </div>
  );
});

export { MapToolbar };
