import { computed, makeObservable, observable, override, runInAction, when } from "mobx";

import { global } from "models/global";
import { LoadableStore } from "models/loadableStore/loadableStore";
import { getWellPads, saveWellPad } from "services/back/wellPads";

import { WellPad } from "./wellPad";

class WellPads extends LoadableStore<WellPad> {
  private unsavedCounter = 0;
  private unsavedPads = observable.array<WellPad>();

  constructor() {
    super(() => getWellPads().then((pads) => pads.map((padRaw) => new WellPad(padRaw))));
    makeObservable(this, {
      selector: override,
      padsIsNotFactual: computed,
    });
  }

  public get selector(): { value: number; label: string }[] | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return [...super.selector!, ...this.unsavedPads.map(({ id, title }) => ({ value: id, label: title }))];
  }

  public at(id: number): WellPad | null | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return super.at(id) ?? this.unsavedPads.find(({ id: padId }) => padId === id);
  }

  public get padsIsNotFactual() {
    return (this.map((el) => el) ?? []).filter(({ isFactual }) => !isFactual);
  }

  public createPad(padTitle: string): WellPad | undefined {
    if (global.licenseRegions.isLoading) {
      console.error("Попытка создать куст до загрузки ЛУ");
      return undefined;
    }

    const foundPad = this.unsavedPads.find(({ title }) => title === padTitle);
    if (foundPad) {
      return foundPad;
    }

    this.unsavedCounter++;
    const newPad = new WellPad({
      id: -this.unsavedCounter,
      title: padTitle,
      fieldId: 2, // FIXME
      isFactual: false,
    });
    this.unsavedPads.push(newPad);
    return newPad;
  }

  public async save() {
    await when(() => global.fieldsTrue.isLoading === false);
    const fieldId = global.fieldsTrue.first!.id;
    for (const pad of this.unsavedPads) {
      const { title, isFactual } = pad;
      const savedPad = await saveWellPad({ title, fieldId, isFactual });
      runInAction(() => (pad.id = savedPad.id));
      this.add(pad);
    }
    this.unsavedPads.clear();
  }
}

export { WellPads };
