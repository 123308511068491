import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { WellUnsaved } from "services/back/wells";
import { conditionally } from "utils/conditionally";

import { Fact } from "../../fact";
import { Well } from "../well";

import type { DRow, WellsList } from "./listFlat";

class WellNodeFactual extends TableNode<DRow, WellNodeFactual> {
  asDRow = (): DRow => ({
    title: this.well.title,
    wellId: this.well.id,
    date: this.well.date.format("YYYY-MM-DD"),

    type: [this.data.wellTypeId, undefined],
    stratum: [this.data.stratumId, undefined],
    mine: [this.data.mineId, undefined],
    licenseZone: [this.data.licenseZoneId, undefined],
    producingObject: [this.producingObjectId ?? null, undefined],

    topX: this.data.topX,
    topY: this.data.topY,
    botX: this.data.botX,
    botY: this.data.botY,

    md: this.data.md,
    oilRate: this.data.oilRate,
    liquidRate: this.data.liquidRate,
    waterCut: this.data.waterCut,
    recoverableResources: this.recoverableResources,
    notEditable: false,

    isComplete: true,
    isDuplicatedWell: false,
  });

  public readonly producingObjectId: number | null;
  public readonly stratumId: number | null;
  public recoverableResources: number | null;

  constructor(parent: WellsList | WellNodeFactual, fact: Fact, private readonly well: Well, stratumId?: number) {
    super(parent);

    makeObservable(this, {
      recoverableResources: observable,
      data: computed,
      updateValue: action,
    });

    this.stratumId = stratumId ?? this.well.data.stratumId;
    this.recoverableResources =
      this.stratumId !== null ? this.well.data.byStratums[this.stratumId]?.recoverableResources ?? null : null;
    if (this.stratumId === null) {
      this.producingObjectId = null;
    } else {
      const stratum = fact.stratums.at(this.stratumId);
      if (!stratum) {
        this.producingObjectId = null;
      } else {
        this.producingObjectId = fact.stratums.getProducingObject(stratum)?.id ?? null;
      }
    }

    const stratumIds = this.well.data.stratumIds;
    if (stratumId === undefined && stratumIds.length > 1) {
      runInAction(() => {
        this.childrenStore = new ChildrenStoreArray(
          this,
          stratumIds
            .filter((id) => id !== this.well.data.stratumId)
            .map((id) => new WellNodeFactual(this, fact, this.well, id))
        );
      });
    } else {
      runInAction(() => {
        this.childrenStore = null;
      });
    }
  }

  public get data() {
    const { stratumIds, byStratums, ...data } = this.well.data;
    return {
      ...data,
      stratumId: this.stratumId,
      well_id: this.well.id,
      recoverableResources: this.recoverableResources,
      ...conditionally(this.stratumId !== null, {
        oilRate: byStratums[this.stratumId!]?.oilRate ?? null,
        liquidRate: byStratums[this.stratumId!]?.liquidRate ?? null,
        waterCut: byStratums[this.stratumId!]?.waterCut ?? null,
      }),
    };
  }

  public get toWellUnsaved(): WellUnsaved | undefined {
    const stratumId = this.stratumId;
    if (stratumId === null || stratumId === undefined) {
      console.error("Base well stratumId unknown");
      return undefined;
    }
    return {
      ...this.data,
      id: this.well.id,
      date: this.data.date.format("YYYY-MM-DD"),
      stratumId,
      recoverableResources: this.recoverableResources,
    };
  }

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    if (key !== "recoverableResources") {
      console.warn(`attempt to edit non editable field: ${key}`);
      return [undefined, undefined];
    }

    const prev = this.recoverableResources;
    this.recoverableResources = newValue;
    return [prev, this.recoverableResources];
  }

  // Legacy
  public isDuplicatedWell = false;
  public readonly isCompleted = true;
}

export { WellNodeFactual };
