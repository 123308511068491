import { useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import classNames from "classnames";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { Fact, useFact } from "models/project/fact/fact";
import { Forecast, useForecast } from "models/project/fact/forecast/forecast";

import { ReservesDepletion } from "./tableModel/tableModel";

import cn from "./reservesDepletion.module.less";

const useColumns = (fact: Fact, forecast: Forecast | null) =>
  useMemo<Column[]>(() => {
    const yearsRange = forecast?.range ?? fact.factRange;
    return [
      {
        dataKey: "name",
        title: "Параметр",
        type: "string",
        width: 300,
        isSticky: true,
        hideFilter: true,
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        type: "string",
        width: 120,
        isSticky: true,
        hideFilter: true,
      },
      ...[...yearsRange].map(
        (year): Column => ({
          dataKey: `${year}`,
          title: `${year}`,
          type: "number",
          width: 100,
          editable: true,
          hideFilter: true,
        })
      ),
    ];
  }, [fact, forecast]);

const Reserves = observer(() => {
  const fact = useFact()!;
  const forecast = useForecast() ?? null;

  const columns = useColumns(fact, forecast);
  const store = useMemo(() => new ReservesDepletion(fact, forecast), [fact, forecast]);

  return (
    <SimpleTableContext
      exportFileName="Параметры объектов разработки. Запасы"
      columns={columns}
      data={store}
      showIndexColumn={false}
      hideExpandColumn
      tableSettingsId="producing_objects_params_reserves"
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: classNames({
            [cn.summaryRow]: indexPath[0] + 1 === 0,
            [cn.tableRowPlain]: expand === undefined,
            [cn.tableRowPrimary]: expand !== undefined && indexPath.length === 1,
            [cn.tableRowSecondary]: expand !== undefined && indexPath.length > 1,
          }),
        }),
        isRowEditable: (tableItem) => tableItem.value.isEditable,
      }}
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
    >
      <PageFrameTitlePortal model={store} onSave={store.submit} permissionSection="tech" />
      <Widget headerClassName={cn.tableHeader} />
    </SimpleTableContext>
  );
});

export { Reserves };
