import { action, computed, makeObservable, observable, runInAction } from "mobx";

import type { TechFlags, TechFlagsStorage, TechResultsForSave } from "services/back/techForecast/request";
import { getTechFlags, setTechFlags } from "services/back/techForecast/request";

import { Forecast } from "../forecast";

type Key = {
  wellId: number;
  stratumId: number | null;
  gtmId: number | null;
};

class TechFlagsManager {
  #flags = observable.map<string, TechFlags>();
  private isLoadingValue: boolean = true;

  constructor(private readonly fc: Forecast) {
    const { fact, forecast } = this.backendStorageKey;
    getTechFlags(fact, forecast!).then((flags) => {
      this.resetFlags(flags);
      runInAction(() => (this.isLoadingValue = false));
    });

    makeObservable<TechFlagsManager, "isLoadingValue" | "resetFlags">(this, {
      isLoadingValue: observable,
      isLoading: computed,
      resetFlags: action,
    });
  }

  public get isLoading(): boolean {
    return this.isLoadingValue;
  }

  public get(key: Key): TechFlags | null | undefined {
    const keyStr = TechFlagsManager.key(key);

    if (this.isLoading) {
      return undefined;
    }
    return this.#flags.get(keyStr) ?? null;
  }

  public async update(items: TechResultsForSave[]) {
    const currentFlags: TechFlagsStorage = Object.fromEntries(this.#flags);
    for (const { forecastProduction, ...key } of items) {
      currentFlags[TechFlagsManager.key(key)] = {
        isCorrectLiquidRateM3: forecastProduction.isCorrectLiquidRateM3,
        isCorrectOilRateT: forecastProduction.isCorrectOilRateT,
        isCorrectWaterCutVol: forecastProduction.isCorrectWaterCutVol,
      };
    }
    const { fact, forecast } = this.backendStorageKey;
    const saved = await setTechFlags(fact, forecast!, currentFlags);
    this.resetFlags(saved);
  }

  private get backendStorageKey() {
    return this.fc.storageKey;
  }

  private resetFlags(flags: TechFlagsStorage | null) {
    this.#flags.replace(flags ?? {});
  }

  private static key({ wellId, gtmId, stratumId }: Key): string {
    return [stratumId, gtmId, wellId].map((v) => v ?? -1).join(":");
  }
}

export { TechFlagsManager };
