import { FC, ReactNode, useState } from "react";
import { Button, Dropdown, Popconfirm } from "antd";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { useForecast } from "models/project/fact/forecast/forecast";

import { useInfrastructure } from "../useInfrastructure";

import { ReactComponent as UploadIcon } from "./icons/uploadIcon.svg";

import cn from "./InfrastructureMap.module.less";

const DESCRIPTION = "Внимание, это приведет к удалению существующей инфраструктуры";

const items = [
  { title: "Заполнить инфраструктуру кустами БФ", children: "Только кусты БФ" },
  { title: "Заполнить инфраструктуру из OIS PIPE", children: "Кусты БФ и инфраструктура OIS PIPE" },
];

type PopConfirmCustomProps = {
  title: string;
  disabled?: boolean;
  description: string;
  children: ReactNode;
  onClick: () => void;
};

const PopconfirmCustom: FC<PopConfirmCustomProps> = ({ title, disabled, description, onClick, children }) => {
  return (
    <Popconfirm
      disabled={disabled}
      title={title}
      description={description}
      onConfirm={onClick}
      okText="Заполнить"
      cancelText="Отмена"
      overlayClassName={cn.importPopConfirm}
    >
      {children}
    </Popconfirm>
  );
};

const ImportOis: FC = observer(() => {
  const [loading, setLoading] = useState(false);

  const fc = useForecast();
  const { fillInfrastructureOisPipeOrMines } = useInfrastructure();

  const handleClick = (isOnlyMines?: boolean) => {
    setLoading(true);
    fillInfrastructureOisPipeOrMines(isOnlyMines)?.then(() => setLoading(false));
  };

  if (!fc) return <></>;

  return (
    <Dropdown
      menu={{
        items: items.map(({ title, children }, key) => ({
          key,
          label: (
            <PopconfirmCustom title={title} description={DESCRIPTION} onClick={() => handleClick(key === 0)}>
              {children}
            </PopconfirmCustom>
          ),
        })),
      }}
    >
      <Button loading={loading} icon={<Icon width="20" height="20" content={<UploadIcon />} viewBox="0 0 16 16" />} />
    </Dropdown>
  );
});

export { ImportOis };
