import { FC } from "react";
import { Scale } from "@okopok/axes_context";

import { GrabPoints } from "../InfrastructureMapManager/selection";

import cn from "./selection.module.less";

type Props = {
  grabPoints: GrabPoints;
  cursorPosition: { x: number; y: number };
  strokeWidth: number;
  scale: Scale;
  zoom: number;
};

const SelectionGrab: FC<Props> = ({ grabPoints, cursorPosition, scale, strokeWidth, zoom }) => {
  const { prevPoint, nextPoint } = grabPoints;
  const scaleMap = {
    16384: "0.01 0.01",
    8192: "0.02 0.02",
    4096: "0.06 0.06",
    2048: "0.06 0.06",
    1024: "0.125 0.125",
    512: "0.125 0.125",
    256: "0.25 0.25",
    128: "0.5 0.5",
    64: "1 1",
    32: "2.5 2.5",
  };
  const dashArray = scaleMap[zoom as keyof typeof scaleMap] ?? "5 5";

  return (
    <>
      <line
        x1={scale.x(nextPoint.x)}
        y1={scale.y(nextPoint.y)}
        x2={scale.x(cursorPosition.x)}
        y2={scale.y(cursorPosition.y)}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
        className={cn.grabLine}
      />
      <line
        x1={scale.x(prevPoint.x)}
        y1={scale.y(prevPoint.y)}
        x2={scale.x(cursorPosition.x)}
        y2={scale.y(cursorPosition.y)}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
        className={cn.grabLine}
      />
    </>
  );
};

export default SelectionGrab;
