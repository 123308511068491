import { FC, useMemo } from "react";
import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { Table } from "@okopok/components/Table/Table";
import { DatePicker, Select, Tooltip } from "antd";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { debugColumnsForSimpleTable } from "elements/debugColumn/debugColumn";
import { Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import type { DRow, EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";
import { Debet as DebetStore } from "models/project/fact/forecast/techPrediction/techPrediction";
import { useProject } from "models/project/project";
import { useDebetContext } from "pages/project/tech/debet/context";
import { getUserPermission } from "services/back/roles";
import { TechFlags } from "services/back/techForecast/request";

import { CustomColumnsButton } from "./customColumns/customColumnsSettings";
import { FilterDetails, StaticFilter } from "./filters/types";

import cn from "./debet.module.less";

const TECH_PREDICTION_DEBET_STATIC_FILTERS: StaticFilter<DRow, EventNode>[] = [
  {
    title: "Только строки без прогноза",
    predicate: (node) => node.forecastProduction === null,
  },
  {
    title: "Только строки с текущей добычей",
    predicate: (node) => (node.byStratums.oilRate ?? 0) > 0,
  },
  {
    title: "Только строки с некорректным прогнозом",
    predicate: (node) => (node.forecastFlags ? Object.values(node.forecastFlags).includes(false) : false),
  },
];

const TECH_PREDICTION_DEBET_FILTER_MAP = {
  wellTitle: {
    type: "stringer",
    predicateFactory: (condition, value) => (eventNode) => {
      value = value.toLowerCase();
      const contains = eventNode.well.title.toLowerCase().includes(value);
      return condition === "contains" ? contains : !contains;
    },
  } as FilterDetails<"stringer", DRow, EventNode, string>,
  eventTitle: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const interventionTitleMatch = eventNode.intervention?.data.gtmTypeId === value;
      const baseProdMatch = eventNode.well.fond === "Base" && value === -1;
      const newProdMatch = eventNode.well.fond === "New" && value === -2;
      const isEqual = interventionTitleMatch || (eventNode.intervention === null && (baseProdMatch || newProdMatch));
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return (
        <Select
          value={value}
          options={[
            ...(global.interventionsTypes.selector ?? []),
            { label: "Базовая добыча", value: -1 },
            { label: "Эксплуатационное бурение", value: -2 },
          ]}
          onSelect={(value) => onChange(value)}
          variant="borderless"
          popupMatchSelectWidth={false}
          labelRender={(label) => (
            <Ellipsis limit={20} position="after">
              {label.label}
            </Ellipsis>
          )}
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number>,
  factIdleMonths: {
    type: "ordering",
    predicateFactory: (condition, value) => (eventNode) => {
      const idleMonths = eventNode.factualProductionFields.factIdleMonths;
      if (idleMonths === undefined || idleMonths === null || value === null) {
        return false;
      }
      switch (condition) {
        case "eq":
          return idleMonths === value;
        case "ne":
          return idleMonths !== value;
        case "ge":
          return idleMonths >= value;
        case "gt":
          return idleMonths > value;
        case "le":
          return idleMonths <= value;
        case "lt":
          return idleMonths < value;
      }
    },
    InputNode: ({ value, onChange }) => {
      return <InputNumber bordered value={value} onUpdate={onChange} />;
    },
  } as FilterDetails<"ordering", DRow, EventNode, number | null>,
  date: {
    type: "ordering",
    predicateFactory: (condition, value) => (eventNode) => {
      const date = eventNode.date;
      if (date === null) {
        return false;
      }
      switch (condition) {
        case "eq":
          return date === value;
        case "ne":
          return date !== value;
        case "ge":
          return date >= value;
        case "gt":
          return date > value;
        case "le":
          return date <= value;
        case "lt":
          return date < value;
      }
    },
    InputNode: ({ value, onChange }) => {
      return <DatePicker value={value} onChange={onChange} />;
    },
  } as FilterDetails<"ordering", DRow, EventNode, Dayjs>,
  wellType: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.wellTypeId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return <Select value={value} options={global.wellTypes.selector} onSelect={(value) => onChange(value)} variant="borderless" />;
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  wellStatus: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.wellStatus === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const options: Array<DRow["wellStatus"]> = ["Добывающая", "Нагнетательная", "Прочего назначения"];
      return (
        <Select value={value} options={options.map((v) => ({ label: v, value: v }))} onSelect={(value) => onChange(value)} variant="borderless" />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, DRow["wellStatus"]>,
  fond: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.fond === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return (
        <Select
          value={value}
          options={["Base", "New"].map((v) => ({ label: v === "Base" ? "Базовый" : "Новый", value: v }))}
          onSelect={(value) => onChange(value)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, "Base" | "New">,
  licenseRegion: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.licenseRegionId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const licenseRegions = useProject()!.licenseRegions;
      const options = licenseRegions.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  wellPad: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.mineId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const pads = useFact()!.wellPads;
      const options = pads.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  stratum: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.stratumId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const stratums = useFact()!.stratums;
      const options = stratums.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  producingObject: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.producingObject?.id === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const producingObjects = useFact()!.producingObjects;
      return <SelectStorable values={[value, undefined]} store={producingObjects} onChange={onChange} variant="outlined" />;
    },
  } as FilterDetails<"equal", DRow, EventNode, number | null>,
};

const FLAG_PARAMS: Record<keyof TechFlags, string> = {
  isCorrectOilRateT: "дебита нефти",
  isCorrectLiquidRateM3: "дебита жидкости",
  isCorrectWaterCutVol: "обводненности",
};
const techFlagsRenderer = (value: TechFlags | null | undefined, row: any) => {
  if (row.indexPath.length === 1) {
    return null;
  }
  if (!value) {
    return <Format>{value}</Format>;
  }
  const invalidParams = [...Object.entries(value)].filter(([, v]) => v === false).map(([k]) => FLAG_PARAMS[k as keyof TechFlags]);
  if (invalidParams.length === 0) {
    return <Format>{null}</Format>;
  }
  const tooltip = `При составлении прогноза получены не физичные значения ${invalidParams.join(", ")}`;
  return (
    <Tooltip title={tooltip}>
      <WarningOutlined className={cn.warn} />
    </Tooltip>
  );
};

const DeleteButton: FC<{ store: DebetStore }> = observer(({ store }) => {
  const forecast = useForecast()!;

  const project = useProject()!;
  const edit = getUserPermission(project, forecast)["tech"];
  if (!edit.value) {
    return null;
  }

  const isNotSelected = store.selectedEvents.length === 0;
  const isNotTechParameters = !store.selectedEvents.some(({ forecastSettings, forecastProduction }) => forecastSettings || forecastProduction);

  const confirm = async () => {
    const wellIdsInfo = store.selectedEvents.map(({ well: { id }, stratumId, intervention }) => ({
      wellId: id,
      gtmId: intervention?.id ?? null,
      stratumId,
    }));
    forecast.techForecastSettings.delete(wellIdsInfo);
  };

  const tooltip = isNotSelected
    ? "Ни одна скважина/ГТМ не выбрана"
    : isNotTechParameters
    ? "Ни для одной из выбранных скважин/ГТМ нет прогноза"
    : "Удалить прогноз у выбранных скважин/ГТМ";

  return (
    <ToolbarButton
      tooltip={{ title: tooltip }}
      popconfirm={{
        title: "Удаление настроек",
        description: "Удалить прогноз для выбранных скважин/ГТМ?",
        onConfirm: confirm,
        onCancel: () => "",
        okText: "Да",
        cancelText: "Нет",
      }}
      disabled={isNotSelected || isNotTechParameters}
      icon={<DeleteOutlined />}
      danger
    />
  );
});

const Debet = observer(() => {
  const store = useDebetContext();

  const columns = useMemo<Column[]>(
    () => [
      ...debugColumnsForSimpleTable([
        {
          dataKey: "wellId",
          title: "ID скважины",
          width: 80,
          hideFilter: true,
        },
        {
          dataKey: "gtmId",
          title: "ID ГТМ",
          width: 80,
          hideFilter: true,
        },
      ]),
      {
        dataKey: "wellTitle",
        title: "Скважина",
        type: "string",
        isSticky: true,
        width: 160,
      },
      {
        dataKey: "eventTitle",
        title: "Мероприятие",
        type: "string",
        isSticky: true,
        width: 260,
      },
      {
        dataKey: "date",
        title: "Дата мероприятия",
        type: "date",
        width: 160,
      },
      {
        dataKey: "wellType",
        title: "Тип заканчивания",
        type: "string",
        width: 160,
      },
      {
        dataKey: "wellStatus",
        title: "Назначение",
        type: "string",
        width: 160,
      },
      {
        dataKey: "factIdleMonths",
        title: "Кол-во месяцев в простое",
        type: "number",
        width: 160,
      },
      {
        dataKey: "wellPad",
        title: "Куст",
        type: "number",
        width: 160,
      },
      {
        dataKey: "fond",
        title: "Фонд",
        type: "string",
        width: 160,
      },
      {
        dataKey: "licenseRegion",
        title: "ЛУ",
        type: "string",
        width: 160,
      },
      {
        dataKey: "producingObject",
        title: "Объект разработки",
        type: "string",
        width: { min: 160, max: 440, competitiveness: 1 },
      },
      {
        title: "Залежь",
        dataKey: "stratum",
        width: { min: 200, max: 300, competitiveness: 1 },
        type: "string",
        hideFilter: true,
        onCell: () => ({ className: cn.remove }),
        onHeaderCell: () => ({ className: cn.tableHeader }),
      },
      // {
      //   dataKey: "stopCriterion",
      //   title: "Достигнутый критерий остановки",
      //   width: 260,
      //   render: (value) => value && <AnnotatedValue {...value} />
      // },
      {
        dataKey: "operationCoef",
        title: "Коэф-т эксплуатации",
        type: "number",
        width: 160,
      },
      {
        dataKey: "liquidDebitMethodTitle",
        key: "liquidDebitMethodTitle",
        title: "Способ расчета дебита жидкости",
        type: "string",
        width: 260,
      },
      {
        dataKey: "oilDebitMethodTitle",
        key: "oilDebitMethodTitle",
        title: "Способ расчета дебита нефти",
        type: "string",
        width: 260,
      },
      {
        dataKey: "liquidRate",
        title: "Стартовый дебит жид-ти, м³/сут",
        type: "number",
        width: 260,
      },
      {
        dataKey: "oilRate",
        title: "Стартовый дебит нефти, т",
        type: "number",
        width: 260,
      },
      {
        dataKey: "waterCut",
        title: "Стартовая обводненность, %",
        type: "number",
        width: 260,
      },
      {
        dataKey: "accumLiquid",
        title: "Накопленная добыча жид-ти, тыс. м³",
        type: "number",
        width: 270,
      },
      {
        dataKey: "accumOil",
        title: "Накопленная добыча нефти, тыс. т",
        type: "number",
        width: 260,
      },
      {
        dataKey: "recoverableResourcesStart",
        title: "Нач. извлекаемые запасы, тыс т",
        type: "number",
        width: 260,
      },
      {
        dataKey: "recoverableResourcesEnd",
        title: "Ост. извлекаемые запасы, тыс т",
        type: "number",
        width: 260,
        onCell: ({ value }) => ({ style: { backgroundColor: (value?.recoverableResourcesEnd ?? 0) < 0 ? "pink" : undefined } }),
      },
      {
        dataKey: "recoverableResourcesRatio",
        title: "Отбор от НИЗ, %",
        type: "number",
        width: 260,
      },
      {
        dataKey: "techFlags",
        key: "techFlags",
        title: "Корректность прогноза",
        type: "string",
        width: 110,
        hideFilter: true,
        isExported: false,
      },
    ],
    []
  );

  return (
    <div className={cn.root}>
      <SimpleTableContext
        columns={columns}
        customRenders={{ techFlags: techFlagsRenderer }}
        data={store}
        exportFileName="Прогноз добычи"
        hideExpandColumn
        selectable
        showIndexColumn={false}
        tableSettingsId="well_debet"
        tableOptions={{
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        }}
        theme={{
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }}
      >
        <PageFrameTitlePortal model={store} onSave={store.save} filter={store.filterManager}>
          <CustomColumnsButton customColumns={store.customColumns} />
          <DeleteButton store={store} />
        </PageFrameTitlePortal>
        <div className={cn.tableWrap}>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </div>
      </SimpleTableContext>
    </div>
  );
});

export { Debet, TECH_PREDICTION_DEBET_FILTER_MAP, TECH_PREDICTION_DEBET_STATIC_FILTERS };
